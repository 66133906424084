const FileDownloader = (data, filename) => {
  const blob = data
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const URL = window.URL || window.webkitURL
    const downloadUrl = URL.createObjectURL(blob)

    if (filename) {
      const a = document.createElement('a')
      if (typeof a.download === 'undefined') {
        window.location = downloadUrl
      } else {
        a.href = downloadUrl
        a.download = filename
        document.body.appendChild(a)
        a.click()
      }
    } else {
      window.location = downloadUrl
    }

    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl)
    }, 100) // cleanup
  }
}

export default FileDownloader

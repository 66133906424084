import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import visiticon from "../../assets/icon/vipstep5.svg";
import closeBtn from "../../assets/icon/close.svg";
import { flexColCenter, backgroundCenter } from "../../styles/CommonCSS";
import Checkbox from "./CheckBox";
import "moment/locale/ko";
import pen from "../../assets/icon/pen.svg";

function Purpose({ setStep, selectedDate, item, setItem, selectedData }) {
  const home = () => {
    setStep("date");
  };

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    if (!etc) {
      setItem({ ...item, visit_purpose: "기타" });
      setEtc("기타");
    }
    setModalOpen(false);
  };
  const visitlist = [
    "일반 관람",
    "판매촉진/영업",
    "기술 및 정보교류",
    "LG 행사 방문",
    "임직원 방문",
    "촬영",
  ];

  const handleCheck = (checked, i, isEtc) => {
    if (!isEtc) {
      setEtc("기타");
    }
    setItem({ ...item, visit_purpose: i });
  };

  const onChange = (e) => {
    const { value } = e.target;
    setEtc(value);
    setItem({ ...item, visit_purpose: value });
  };
  const [etc, setEtc] = useState("기타");
  useEffect(() => {
    setItem({ ...item, visit_purpose: selectedData.visit_purpose });

    if (
      selectedData.visit_purpose &&
      visitlist.find((purpose) => purpose === selectedData.visit_purpose) ===
        undefined
    ) {
      setEtc(selectedData.visit_purpose);
    }
  }, []);

  return (
    <>
      {modalOpen && (
        <ModalDiv isOpen={modalOpen}>
          <ModalContentDiv>
            <ModalContainer>
              <CloseDiv onClick={closeModal}>
                <ModalClose />
              </CloseDiv>
              <InputBox
                type="text"
                value={item.visit_purpose}
                onChange={onChange}
              ></InputBox>
              <ModalButton onClick={closeModal}>확 인</ModalButton>
            </ModalContainer>
          </ModalContentDiv>
        </ModalDiv>
      )}
      <Container>
        <Date onClick={home}>
          {moment(selectedDate).format("YY.MM.DD (dd)")}
        </Date>
        <Icon src={visiticon} />
        <Title>
          <span>방문 목적</span>을 선택해주세요
        </Title>
        <CheckWrap>
          {visitlist.map((i, idx) => (
            <CheckBoxWrapper key={`check${idx}`}>
              <Checkbox
                onChange={(e) => handleCheck(e.target.checked, i)}
                checked={item?.visit_purpose === i}
              />
              <p>{i}</p>
            </CheckBoxWrapper>
          ))}
          <CheckBoxWrapper>
            <Checkbox
              onChange={(e) => handleCheck(e.target.checked, etc, true)}
              checked={item.visit_purpose === etc}
            />
            <p>기타 : </p>
            <Ex onClick={openModal}>
              <P>{etc}</P>
              <ImgBox>
                <Img src={pen} />
              </ImgBox>
            </Ex>
          </CheckBoxWrapper>
        </CheckWrap>
        <BtnBox>
          <Button
            onClick={() => {
              setStep("visitor");
            }}
          >
            이전
          </Button>
          <NextButton
            disabled={!item.visit_purpose}
            onClick={() => {
              setStep("type");
            }}
          >
            다음
          </NextButton>
        </BtnBox>
        <StepBox>
          <span />
          <span />
          <span />
          <span />
        </StepBox>
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  width: 700px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Date = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 150px;
  height: 36px;
  background: #aeb0b3;
  box-shadow: 0px 3px 3px rgb(90 91 94 / 26%);
  border-radius: 16px;
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 16px;
  cursor: pointer;
`;

const Icon = styled.img`
  margin-top: 95px;
`;

const Title = styled.p`
  margin-top: 20px;
  font-size: 24px;
  font-weight: 500;
  color: #5a5b5e;

  & > span {
    font-weight: 700;
  }
`;

const CheckWrap = styled.div`
  width: 700px;
  height: 334px;
  border: 1px solid rgba(174, 176, 179, 0.5);
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 40px;
  background: #fff;
  padding: 60px 70px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 30px;
`;
const CheckBoxWrapper = styled.label`
  display: flex;
  font-size: 22px;
  font-weight: 700;
  color: #5a5b5e;
  height: 30px;
  width: 299px;
`;

const Ex = styled.div`
  float: left;
  height: 30px;
  width: fit-content;
  margin-left: 10px;
  font-size: 22px;
  font-weight: 700;
  color: #5a5b5e;
  cursor: pointer;
`;

const BtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  width: 156px;
  height: 54px;
  border: 1px solid rgb(245 245 245 / 40%);
  box-shadow: 2px 10px 20px rgb(90 91 94 / 12%);
  border-radius: 10px;
  box-sizing: border-box;
  color: #aeb0b3;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  &:hover {
    color: #fff;
    background: #cb2058;
    box-shadow: 8px 10px 20px rgb(90 91 94 /26%);
    border: 0;
    font-weight: 700;
  }
`;

const NextButton = styled(Button)`
  color: #fff;
  background: #cb2058;
  box-shadow: 8px 10px 20px rgb(90 91 94 /26%);
  border: 0;
  font-weight: 700;
`;

const StepBox = styled.div`
  width: 106px;
  height: 22px;
  margin-top: 62px;

  & > span {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    display: block;
    background: #fff;
    float: left;
    margin-right: 10px;
    box-shadow: 0px 2px 4px inset rgb(90 91 94 / 12%);

    &:nth-of-type(3) {
      background: #cb2058;
      border: 1px solid rgb(255 255 255 /10%);
      box-shadow: -3px 4px 7px 2px rgb(203 32 88 / 26%);
      box-sizing: border-box;
    }

    &:nth-of-type(4) {
      margin-right: 0;
    }
  }
`;

const ModalDiv = styled.div`
  z-index: 9999;
  ${flexColCenter}
  display:flex;
  position: fixed;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(25, 25, 25, 0.6);
`;

const ModalContentDiv = styled.div`
  width: 400px;
  height: 300px;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: white;
`;

const CloseDiv = styled.div`
  position: absolute;
  top: -0px;
  right: 0;
  cursor: pointer;
`;

const ModalClose = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${closeBtn});
  ${backgroundCenter}
`;

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 90px;
  width: 100%;
  height: 100%;
`;

const InputBox = styled.input`
  -webkit-appearance: none; /* 네이티브 외형 감추기 */
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 360px;
  height: 60px;
  padding: 19px 10px;
  font-size: 16px;
  box-shadow: 2px 5px 10px inset rgb(203 32 88 / 12%);

  &:focus {
    outline: none;
    border: 1px solid #cb2058;
  }
`;

const ModalButton = styled.button`
  color: #fff;
  width: 40%;
  text-align: center;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 9px 0;
  margin-top: 30px;
  font-weight: bold;
  font-size: 20px;
  background: #cb2058;
`;

const ImgBox = styled.div`
  float: left;
  margin: 5px 0 0 5px;
`;

const Img = styled.img`
  display: block;
`;

const P = styled.p`
  float: left;
  &::after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 1px solid #5a5b5e;
    position: relative;
    top: -5px;
  }
`;
export default Purpose;

import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

import LgLight from "../assets/fonts/LGSMHATL.woff";
import LgRegular from "../assets/fonts/LGSMHATR.woff";
import LgSemibold from "../assets/fonts/LGSMHATSB.woff";
import LgBold from "../assets/fonts/LGSMHATB.woff";

import LgLight2 from "../assets/fonts/LGSMHATL.woff2";
import LgRegular2 from "../assets/fonts/LGSMHATR.woff2";
import LgSemibold2 from "../assets/fonts/LGSMHATSB.woff2";
import LgBold2 from "../assets/fonts/LGSMHATB.woff2";

import LgLightT from "../assets/fonts/LGSMHATL.TTF";
import LgRegularT from "../assets/fonts/LGSMHATR.TTF";
import LgSemiboldT from "../assets/fonts/LGSMHATSB.TTF";
import LgBoldT from "../assets/fonts/LGSMHATB.TTF";

const GlobalStyle = createGlobalStyle`
    ${reset};
    @font-face {
      font-family: Lg;
      src: url(${LgBold}) format("woff"),
      url(${LgBold2}) format("woff2"),
      url(${LgBoldT}) format("truetype");
      font-weight: 700;
      font-style: normal;
    };
    @font-face {
      font-family: Lg;
      src: url(${LgSemibold}) format("woff"),
      url(${LgSemibold2}) format("woff2"),
      url(${LgSemiboldT}) format("truetype");
      font-weight: 600;
      font-style: normal;
    };
    @font-face {
      font-family: Lg;
      src: url(${LgRegular}) format("woff"),
      url(${LgRegular2}) format("woff2"),
      url(${LgRegularT}) format("truetype");
      font-weight: 500;
      font-style: normal;
    };
    @font-face {
      font-family: Lg;
      src: url(${LgLight}) format("woff"),
      url(${LgLight2}) format("woff2"),
      url(${LgLightT}) format("truetype");
      font-weight: 300;
      font-style: normal;
    };
    body{
      padding: 0;
      margin: 0;
      font-family: 'Lg', sans-serif;
      font-weight: 500;
      font-size: 100%;
      word-break: keep-all;
      line-height: 1.5;
      background-color:white;
      color: #191919;
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        display: none;
      }
    };
    button{
      all: unset;
      cursor:pointer;
    }
    input:focus {
      outline: none;
    }
    html,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    section {
    display: block;
    }
    ol,
    ul {
    list-style: none;
    }
    blockquote,
    q {
    quotes: none;
    }
    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
    content: "";
    content: none;
    }
    table {
    border-collapse: collapse;
    border-spacing: 0;
    }

    input:focus {
    outline: none;
    }
    textarea:focus {
      outline: none;
    }

    a {
    color: inherit;
    text-decoration: none;
    }
    a:hover, a:active {text-decoration: none; color:none;}
`;

export default GlobalStyle;

import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
const CheckIcon = styled.svg`
  fill: none;
  stroke: #cb2058;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  transition: all 0.3s;
  box-shadow: 2px 4px 10px inset rgb(90 91 94 / 12%);
  border: 1px solid rgb(255 255 255/70%);
  border-color: ${(props) =>
    props.checked ? "#CB2058" : "rgb(255 255 255 / 70%)"};
  float: left;
  margin-right: 20px;
`;

const Checkbox = ({ onClick, checked, ...props }) => (
  <CheckboxContainer onClick={onClick}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <CheckIcon viewBox="0 0 24 24">
        {checked ? <polyline points="19 7 10 17 5 12" /> : null}
      </CheckIcon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;

import React from "react";
import styled, { css } from "styled-components";
import time from "../../assets/icon/time.svg";

function Input({ value, name, onChange, ...props }) {
  return (
    <InputBox
      autocomplete="off"
      value={value}
      name={name}
      onChange={onChange}
      {...props}
    />
  );
}

const InputBox = styled.input`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(174, 176, 179, 0.26);
  border-radius: 2px;
  padding: 10px;
  font-size: 100%;
  ${(props) =>
    props.name?.toLowerCase().includes("time") &&
    css`
      background-image: url(${time});
      background-position: center right 10px;
      background-repeat: no-repeat;
    `}

  &::placeholder {
    color: #5a5b5e;
  }

  &:focus {
    border: 1px solid #cb2058;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default Input;

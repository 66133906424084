import React, { useState } from "react";
import styled, { css } from "styled-components";
import Calendar from "react-calendar";
import moment from "moment";
import "../../styles/Calendar.css";
import adminIcon from "../../assets/icon/adminicon.svg";

function Step1({ setStep, setSelectedDate, selectedDate }) {
  const [value, setValue] = useState(selectedDate);
  const [visible, setVisible] = useState(true);

  const onClicked = (date) => {
    setSelectedDate(date);
    setStep("list");
  };

  const onViewChange = (action) => {
    if (action.view === "month") {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  return (
    <div>
      <Calendar
        onClickDay={(val) => {
          setValue(val);
        }}
        value={value || null}
        locale="en-GB"
        calendarType="US"
        onViewChange={onViewChange}
      />
      {visible && (
        <Btn
          disabled={!value}
          onClick={() => {
            onClicked(value);
          }}
        >
          확 인
        </Btn>
      )}

      <AdminBtn href="/admin" target="_blank" rel="noreferrer">
        <Icon src={adminIcon} />
      </AdminBtn>
    </div>
  );
}

const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  width: 156px;
  height: 54px;
  border: 1px solid #fcfcfc;
  box-shadow: 2px 10px 20px rgba(90, 91, 94, 0.12);
  border-radius: 10px;
  box-sizing: border-box;
  color: #aeb0b3;
  font-weight: 500;
  font-size: 20px;
  background: #fff;
  ${({ disabled }) =>
    !disabled &&
    css`
      background: #cb2058;
      color: #fff;
      font-weight: 700;
      border: 0;
    `};
  background: .calendar__navigation = > {
    display: block;
  }
`;

const AdminBtn = styled.a`
  position: absolute;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 100%;
`;

export default Step1;

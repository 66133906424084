import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Icon } from "../../assets/icon/drop.svg";
import time from "../../assets/icon/time.svg";

function Select({
  selectList,
  selectData,
  setSelectData,
  name,
  placeholder,
  exceptAll,
}) {
  const selectRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => {
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (value) => () => {
    setSelectData({
      ...selectData,
      [name]: value,
    });
    setIsOpen(false);
  };

  const closeAllSelect = ({ target }) => {
    if (selectRef.current) {
      if (target.contains(selectRef.current)) {
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeAllSelect);
    return () => document.removeEventListener("click", closeAllSelect);
  }, []);

  return (
    <SelectDiv ref={selectRef}>
      <SelectButton onClick={toggling}>
        {name.includes("start_time")
          ? selectData && selectData[name] === "12:00"
            ? `${selectData[name]} 1회차`
            : selectData && selectData[name] === "18:00"
            ? `${selectData[name]} 2회차`
            : selectData && selectData[name] === "19:00"
            ? `${selectData[name]} 3회차`
            : selectData[name]
          : selectData && selectData[name]
          ? selectData[name]
          : placeholder || "전체"}

        {!name?.includes("Time") || name === "startTimePoint" ? (
          <Arrow open={isOpen} />
        ) : (
          <TimeIcon open={isOpen} />
        )}
      </SelectButton>
      {isOpen && (
        <SelectOptionDiv name={name}>
          {!exceptAll && (
            <SelectOption onClick={onOptionClicked("")}>전체</SelectOption>
          )}
          {selectList?.map((select, index) => (
            <SelectOption
              onClick={onOptionClicked(select)}
              key={`select${select}${index}`}
            >
              {select}
            </SelectOption>
          ))}
        </SelectOptionDiv>
      )}
    </SelectDiv>
  );
}

const SelectDiv = styled.div`
  position: relative;
  height: 34px;
  cursor: pointer;
`;

const SelectButton = styled.button`
  position: relative;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(174, 176, 179, 0.26);
  border-radius: 2px;
  padding: 0 10px;
  font-size: 100%;

  &::placeholder {
    color: #5a5b5e;
  }

  &:focus {
    border: 1px solid #cb2058;
  }
`;

const SelectOptionDiv = styled.div`
  box-shadow: 0px 3px 8px rgba(203, 32, 88, 0.06);
  border-radius: 3px;
  z-index: 50;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  padding: 4px;
  max-height: ${(props) =>
    props.name?.toLowerCase().includes("time") ? "320px" : "204px"};
  overflow-y: overlay;
`;

const SelectOption = styled.div`
  box-sizing: border-box;
  height: 40px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: rgba(203, 32, 88, 0.06);
    transition: 0.2s ease-in-out;
  }
  ${(props) => (props.open ? "rotate(180deg)" : "rotate(0deg)")};
`;

const Arrow = styled(Icon)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;
const TimeIcon = styled.svg`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 6px;
  right: 10px;
  background-image: url(${time});
  background-position: center right;
  background-repeat: no-repeat;
`;

export default Select;

/* eslint-disable no-alert */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment-timezone";
import axios from "axios";
import B2BTable from "./B2BTable";
import FilterSelect from "./FilterSelect";
import Input from "./Input";
import Pagenation from "./Pagenation";
import fileDownloader from "../../modules/FileDownloader";
import Modal from "./Modal";
import DelModal from "./DelModal";
import AddModal from "./AddModal";
import InputSelect from "./InputSelect";

function B2B(props) {
  const [selectData, setSelectData] = useState({
    lang: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    company: "",
    companyNote: "",
    visitorType: "",
    visitPurpose: "",
  });

  // 추가 modal
  const [modalOpen, setModalOpen] = useState(false);
  const [item, setItem] = useState({});
  const companyList = [
    "LGSP",
    "㈜LG",
    "LG전자",
    "LG화학",
    "LG엔솔",
    "LG디스플레이",
    "LG이노텍",
    "LG생활건강",
    "LGCNS",
    "LG U+",
    "LG인화원",
    "LGAI 연구원",
    "기타",
  ];
  const interestingList = [
    "5G",
    "로봇",
    "OLED",
    "VS(자동차)",
    "에너지",
    "소재/부품",
    "스마트팩토리",
    "스마트홈",
    "바이오",
    "뷰티",
  ];

  const timeList = [
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
  ];

  const editItem = () => {
    setModalOpen(true);
    setItem(item);
  };

  const modalClose = () => {
    setModalOpen(false);
    setItem({});
  };

  const inputFileRef = useRef(null);
  const [isUploading, setUploading] = useState(false);
  const uploadClickHandler = () => {
    inputFileRef.current.click();
  };
  const handleFileOnChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      if (file && !isUploading) {
        setUploading(true);
        const fd = new FormData();
        fd.append("excel", file);
        axios
          .post(
            `https://lginnovationgallery.com/api/admin/vip/upload/excel`,
            fd
          )
          .then(() => {
            alert("업로드 되었습니다.");
            setUploading(false);
            getList();
          })
          .catch((error) => {
            setUploading(false);
            console.log(error);
            alert("업로드에 실패했습니다.");
          });
      }
    };
    if (file) reader.readAsDataURL(file);
  };

  const handleFileDownload = () => {
    axios
      .get(
        `https://lginnovationgallery.com/api/admin/vip/download/excel?${filter.replace(
          "&",
          ""
        )}${search ? `&keyword=${search}` : ""}`,
        {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          responseType: "blob",
        }
      )
      .then(async (res) => {
        const fileName = `download.xlsx`;
        fileDownloader(res.data, fileName);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFileDownload2 = () => {
    console.log();
    axios
      .get(
        `https://lginnovationgallery.com/api/admin/vip/download/excel/targets?reservationIds=${checkItems.toString()}`,
        {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          responseType: "blob",
        }
      )
      .then(async (res) => {
        const fileName = `download.xlsx`;
        fileDownloader(res.data, fileName);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // deleteitem

  const [delOpen, setDelOpen] = useState(false);

  const deleteModal = () => {
    if (checkItems.length > 0) {
      setDelOpen(true);
    } else {
      setDelOpen(false);
    }
  };
  const closemodal = () => {
    setDelOpen(false);
  };

  const deleteItem = async () => {
    await axios
      .delete(`https://lginnovationgallery.com/api/admin/vip/delete`, {
        params: {
          reservationIds: checkItems,
        },
      })
      .then((res) => {
        setDelOpen(false);
        getList();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  // 15개 마다 pagenation
  const [list, setList] = useState([]);
  const [langFilterList, setLangFilterList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [filter, setFilter] = useState("");
  const [filters, setFilters] = useState({
    perPage: 15,
    page: 1,
    lastRowNum: 0,
  });
  const [pages, setPages] = useState({
    currentCount: 0,
    currentPage: 1,
    perPage: 15,
    totalPages: 1,
  });
  const filtering = () => {
    let filterTemp = [];
    for (const key of Object.keys(selectData)) {
      if (selectData[key]) {
        if (key.includes("Time")) {
          filterTemp = [
            ...filterTemp,
            `&${key}=${selectData[key].replaceAll(":", "")}`,
          ];
        } else {
          filterTemp = [...filterTemp, `&${key}=${selectData[key]}`];
        }
      }
    }

    setSearch("");
    setFilter(filterTemp.toString().replaceAll(",", ""));
    setFilters({ ...filters, page: 1 });
  };

  const [flag, setFlag] = useState(false);
  const getList = async () => {
    if (!flag) {
      setFlag(true);
      await axios
        .get(
          `https://lginnovationgallery.com/api/admin/vip?lastRowNum=${
            filters.lastRowNum
          }&perPage=15&page=${filters.page}${filter || ""}${
            search ? `&keyword=${search}` : ""
          }`
        )
        .then((res) => {
          const { result } = res.data;
          setList(result.list);
          setPages({
            currentCount: result.currentCount,
            currentPage: result.currentPage,
            perPage: result.perPage,
            totalPages: result.totalPages,
            totalCount: result.totalCount,
          });
          setLangFilterList(result.langFilterList);
          setProgramList(result.programFilterList);
          setFilterList([
            {
              name: "company",
              list: result.companyFilterList,
              label: "회사",
            },
            {
              name: "visitorType",
              serverName: "visitor_type",
              list: result.visitorTypeFilterList,
              label: "방문객유형",
            },
            {
              name: "visitPurpose",
              serverName: "visit_purpose",
              list: result.visitPurposeFilterList,
              label: "방문목적",
            },
          ]);
          setFlag(false);
        })
        .catch((error) => {
          console.log(error.response);
          setFlag(false);
        });
    }
  };

  useEffect(() => {
    getList();
  }, [filter, filters, pages]);

  const [checkItems, setCheckItems] = useState([]);
  const [search, setSearch] = useState("");

  const changeSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const onEnterPress = (e) => {
    if (search.replace(/(^\s*)|(\s*$)/gi, "") !== "") {
      if (e.keyCode === 13 && e.shiftKey === false && search) {
        e.preventDefault();
        searching();
      }
    }
  };

  const searching = () => {
    getList();
  };

  // 추가하기
  const saveAddItem = async () => {
    const params = {
      ...item,
      startDt: item.startDt
        ? `${moment(item.startDt).format("YYYY-MM-DD")} ${String(item.startTime)
            .replace(/\s/gi, "")
            .replace(/(\d{2})(\d{2})/, "$1:$2")}`
        : "",
      endDt: item.endDt
        ? `${moment(item.endDt).format("YYYY-MM-DD")} ${String(item.endTime)
            .replace(/\s/gi, "")
            .replace(/(\d{2})(\d{2})/, "$1:$2")}`
        : "",
      reservedDt: item.reservedDt
        ? `${moment(item.reservedDt).format("YYYY-MM-DD")} ${String(
            item.reservedTime
          )
            .replace(/\s/gi, "")
            .replace(/(\d{2})(\d{2})/, "$1:$2")}`
        : "",
      visitorMount1: item.visitorMount1 ? String(item.visitorMount1) : "0",
      visitorMount2: item.visitorMount2 ? String(item.visitorMount2) : "0",
      interesting: item.interesting ? item.interesting.toString() : "",
    };
    if (!flag) {
      setFlag(true);
      await axios
        .post(`https://lginnovationgallery.com/api/admin/vip/create`, params)
        .then((res) => {
          setTimeout(() => {
            setFlag(false);
            modalClose();
            // window.location.reload();
          }, []);
        })
        .catch((error) => {
          console.log(error.response);
          setTimeout(() => {
            setFlag(false);
          }, []);
        });
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    setItem({ ...item, [name]: value });
  };

  return (
    <>
      {modalOpen && (
        <Modal open={modalOpen} close={modalClose} onClick={saveAddItem}>
          <AddModal
            item={item}
            setItem={setItem}
            filterList={filterList}
            programList={programList}
            langFilterList={langFilterList}
            interestingList={interestingList}
            timeList={timeList}
            companyList={companyList}
          />
        </Modal>
      )}
      <FliterDiv>
        <FilterSelect
          selectList={langFilterList}
          selectData={selectData}
          setSelectData={setSelectData}
          name="lang"
          label="언어"
        />
        <FilterSelect
          label="연-월-일"
          name="date"
          selectData={selectData}
          setSelectData={setSelectData}
        />
        <FilterSelect
          label="시작 시간"
          name="startTime"
          selectList={timeList}
          selectData={selectData}
          setSelectData={setSelectData}
        />
        <FilterSelect
          label="종료 시간"
          name="endTime"
          selectList={timeList}
          selectData={selectData}
          setSelectData={setSelectData}
        />
        {filterList?.map(
          (fliter, index) =>
            index !== 0 && (
              <FilterSelect
                key={`filter${index}`}
                selectList={fliter.list}
                selectData={selectData}
                setSelectData={setSelectData}
                name={fliter.name}
                label={fliter.label}
              />
            )
        )}
        <FilterBtn onClick={filtering}>적용</FilterBtn>
      </FliterDiv>
      <ExcelDiv>
        <ExcelFilterDiv>
          <InvisibleInput
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={handleFileOnChange}
            ref={inputFileRef}
          />
          <ExcelButton color="#77797c" onClick={uploadClickHandler}>
            엑셀 업로드
          </ExcelButton>
          <ExcelButton color="#5A5B5E" onClick={handleFileDownload}>
            엑셀 전체 다운로드
          </ExcelButton>
          <ExcelButton color="#5A5B5E" onClick={handleFileDownload2}>
            엑셀 선택 다운로드
          </ExcelButton>
          <SearchBox>
            <Input
              value={search}
              onChange={changeSearch}
              onKeyDown={onEnterPress}
            />
          </SearchBox>
          <SearchBtn onClick={searching}>검색</SearchBtn>
        </ExcelFilterDiv>
        <ModifiyBtn onClick={editItem}>추가</ModifiyBtn>
        {delOpen && (
          <DelModal
            open={delOpen}
            closemodal={closemodal}
            deleteItem={deleteItem}
          />
        )}
        <DeleteBtn onClick={deleteModal}>삭제</DeleteBtn>
      </ExcelDiv>
      <B2BTable
        list={list}
        filterList={filterList}
        companyList={companyList}
        programList={programList}
        langFilterList={langFilterList}
        interestingList={interestingList}
        timeList={timeList}
        checkItems={checkItems}
        setCheckItems={setCheckItems}
      />
      <Pagenation filters={filters} setFilters={setFilters} pages={pages} />
    </>
  );
}

const FliterDiv = styled.div`
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  gap: 10px;
  width: 100%;
`;

const FilterBtn = styled.button`
  background-color: #cb2058;
  color: white;
  width: 60px;
  height: 34px;
  text-align: Center;
  border-radius: 2px;
  font-size: 12px;
`;

const ExcelDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 26px;
  margin-top: 50px;
`;

const ExcelFilterDiv = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const SearchBox = styled.div`
  height: 100%;
  margin-left: 4px;
  margin-right: 6px;
  font-size: 10px;
`;

const SearchBtn = styled.button`
  background-color: #cb2058;
  color: white;
  width: 48px;
  height: 26px;
  text-align: center;
  border-radius: 2px;
  font-size: 10px;
`;

const ExcelButton = styled.button`
  height: 100%;
  box-sizing: border-box;
  background-color: ${(props) => props.color};
  color: white;
  font-size: 10px;
  margin-right: 6px;
  padding: 0 7px;
  border-radius: 2px;
  border: 1px solid rgba(174, 176, 179, 0.25);
`;

const DeleteBtn = styled.button`
  background-color: #aeb0b3;
  color: white;
  width: 48px;
  height: 26px;
  text-align: center;
  border-radius: 2px;
  font-size: 10px;
`;

const ModifiyBtn = styled(DeleteBtn)`
  background-color: #cb2058;
  margin-right: 10px;
`;

const InvisibleInput = styled.input`
  display: none;
`;

export default B2B;

import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import moment from "moment-timezone";
import Input from "./Input";
import AddFilter from "./AddFilter";
import Checkbox from "./CheckBox";
import InputSelect from "./InputSelect";

function AddModal({
  item,
  setItem,
  filterList,
  programList,
  langFilterList,
  interestingList,
  timeList,
  companyList,
}) {
  const onChange = (e) => {
    const { value, name } = e.target;
    setItem({ ...item, [name]: value });
  };

  const handleAllCheck = (checked) => {
    if (checked) {
      setItem({ ...item, interesting: interestingList });
    } else {
      setItem({ ...item, interesting: [] });
    }
  };

  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setItem({
        ...item,
        interesting: item.interesting ? [...item.interesting, id] : [id],
      });
    } else {
      setItem({
        ...item,
        interesting: item?.interesting.filter((el) => el !== id),
      });
    }
  };

  return (
    <>
      <Label>추가하기</Label>
      <ModalLine>
        <ModalLabel>
          <InputLabel>제목</InputLabel>
          <Input value={item?.title || ""} name="title" onChange={onChange} />
        </ModalLabel>
      </ModalLine>
      <ModalLineDiv>
        <AddFilter
          label="언어"
          selectList={langFilterList}
          item={item}
          setItem={setItem}
          name="lang"
          onChange={onChange}
          value={item.lang || ""}
        />

        <ModalLineDiv1>
          <span style={{ marginBottom: "7px" }}>&nbsp;</span>
          <AddFilter
            label="요청일"
            name="reservedDt"
            item={item}
            setItem={setItem}
            value={item.reservedDt || ""}
          ></AddFilter>

          <InputSelect
            value={
              item.reservedTime
                ? String(item.reservedTime)
                    .replace(/\s/gi, "")
                    .replace(/(\d{2})(\d{2})/, "$1:$2")
                : ""
            }
            name="reservedTime"
            selectList={timeList}
            selectData={item}
            setSelectData={setItem}
            onChange={onChange}
            style={{ maxWidth: "130px" }}
          />
        </ModalLineDiv1>
      </ModalLineDiv>
      <ModalLineDiv>
        <AddFilter
          label="예약일자"
          name="startDt"
          item={item}
          setItem={setItem}
          onChange={onChange}
          value={item.startDt || ""}
        />
        <InputSelect
          name="startTime"
          selectList={timeList}
          selectData={item}
          setSelectData={setItem}
          onChange={onChange}
          value={
            item.startTime
              ? String(item.startTime)
                  .replace(/\s/gi, "")
                  .replace(/(\d{2})(\d{2})/, "$1:$2")
              : ""
          }
          placeholder="00:00"
        />
        <span style={{ marginBottom: "7px" }}>~</span>
        <AddFilter
          name="endDt"
          item={item}
          setItem={setItem}
          value={item.endDt || ""}
        />
        <InputSelect
          name="endTime"
          selectList={timeList}
          selectData={item}
          setSelectData={setItem}
          onChange={onChange}
          value={
            item.endTime
              ? String(item.endTime)
                  .replace(/\s/gi, "")
                  .replace(/(\d{2})(\d{2})/, "$1:$2")
              : ""
          }
          placeholder="00:00"
          style={{ maxWidth: "130px" }}
        />
      </ModalLineDiv>
      <ModalLine>
        <ModalLabel>
          <InputLabel>고객사 방문인원(명)</InputLabel>
          <Input
            type="number"
            pattern="[0-9]*"
            value={item.visitorMount2 || ""}
            name="visitorMount2"
            onChange={onChange}
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>LG 방문인원(명)</InputLabel>
          <Input
            type="number"
            pattern="[0-9]*"
            value={item.visitorMount1 || ""}
            name="visitorMount1"
            onChange={onChange}
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>방문인원(총인원)</InputLabel>
          <TotalInput
            readOnly
            name="total"
            value={
              Number(item.visitorMount1) + Number(item.visitorMount2) || ""
            }
          />
        </ModalLabel>
      </ModalLine>
      <ModalLine>
        <ModalLabel>
          <InputLabel>요청자</InputLabel>
          <Input value={item.name || ""} name="name" onChange={onChange} />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>직책</InputLabel>
          <Input
            value={item.position || ""}
            name="position"
            onChange={onChange}
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>팀명</InputLabel>
          <Input value={item.team || ""} name="team" onChange={onChange} />
        </ModalLabel>
      </ModalLine>
      <ModalLine>
        <ModalLabel>
          <AddFilter
            label="회사"
            name="company"
            selectList={companyList}
            item={item}
            setItem={setItem}
            value={item.company || "선택"}
            onChange={onChange}
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>비고</InputLabel>
          <Input value={item.companyNote || ""} name="companyNote" onChange={onChange} />
        </ModalLabel>
      </ModalLine>
      <ModalLine>
        {filterList?.map(
          (fliter, index) =>
            index !== 0 && (
              <AddFilter
                key={`filter${index}`}
                selectList={fliter.list}
                name={fliter.name}
                label={fliter.label}
                item={item}
                setItem={setItem}
              />
            )
        )}
      </ModalLine>
      <ModalLine>
        <ModalLabel>
          <InputLabel>고객사정보</InputLabel>
          <Input value={item.info || ""} name="info" onChange={onChange} />
        </ModalLabel>
        <ModalLabel>
          <AddFilter
            label="투어프로그램"
            name="program"
            selectList={programList}
            item={item}
            setItem={setItem}
            value={item.program || ""}
            onChange={onChange}
          />
        </ModalLabel>
      </ModalLine>
      <ModalLine>
        <ModalLabel>
          <InputLabel>주요 관심분야</InputLabel>
          <InterestingTxt>
            <CheckBoxWrapper key="Allcheck">
              <Checkbox
                onChange={(e) => handleAllCheck(e.target.checked)}
                checked={interestingList.length === item.interesting?.length}
              />
              <CheckText>전체</CheckText>
            </CheckBoxWrapper>
          </InterestingTxt>
          <CheckWrap>
            {interestingList.map((interesting, idx) => (
              <CheckBoxWrapper key={`check${idx}`}>
                <Checkbox
                  value={interesting}
                  onChange={(e) =>
                    handleSingleCheck(e.target.checked, interesting)
                  }
                  checked={
                    item.interesting
                      ? item.interesting.includes(interesting)
                      : false
                  }
                />
                <CheckText>{interesting}</CheckText>
              </CheckBoxWrapper>
            ))}
          </CheckWrap>
        </ModalLabel>
      </ModalLine>
    </>
  );
}

const Label = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #cb2058;
  margin-bottom: 20px;
`;

const InputLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #5a5b5e;
  margin-bottom: 4px;
  padding-left: 2px;
`;

const ModalLine = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
`;

const ModalLineDiv = styled(ModalLine)`
  gap: 0;

  & > span {
    margin: 0 10px;
  }
`;
const ModalLineDiv1 = styled(ModalLine)`
  gap: 0;
  max-width: 290px;
  margin-bottom: 0;

  & > span {
    margin: 0 10px;
  }
`;

const ModalLabel = styled.div`
  flex: 1;
`;

const CheckWrap = styled.div`
  background: #fff;
  display: grid;
  grid-template-columns: repeat(5, 3fr);
  row-gap: 15px;
  margin-top: 15px;
`;
const CheckBoxWrapper = styled.label`
  display: flex;
  font-weight: 700;
  color: #5a5b5e;
`;

const CheckText = styled.p`
  margin-left: 10px;
  font-size: 12px;
`;

const InterestingTxt = styled.div`
  margin-top: 15px;
`;

const TotalInput = styled.input`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: none;
  padding: 10px;
  font-size: 100%;

  &:focus {
    border: unset;
  }
`;

export default AddModal;

import { css } from "styled-components";

export const flexColCenter = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const backgroundCenter = css`
  background-position: center;
  background-repeat: no-repeat;
`;

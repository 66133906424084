import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { Link, withRouter } from "react-router-dom";
import logo from "../../assets/icon/logo.svg";
import { ReactComponent as Arrow } from "../../assets/icon/arrow.svg";

function Navigation({ location: { pathname } }) {
  const [open, setOpen] = useState(false);

  const navOpen = () => {
    setOpen(!open);
  };
  return (
    <NavigationContainer open={open}>
      <LogoDiv>
        <Img src={logo} />
      </LogoDiv>
      <Label>
        <LabelLine />
        <p>관리자</p>
        <ArrowIcon color="#cb2058" />
      </Label>
      <BtnImg onClick={navOpen}>
        <ArrowIcon $rotate open={open} />
      </BtnImg>
      <LinkDiv
        selected={pathname === "/admin/b2b"}
        to="/admin/b2b"
        onClick={() => setOpen(false)}
      >
        B2B
      </LinkDiv>
      <LinkDiv
        selected={pathname === "/admin/b2c"}
        to="/admin/b2c"
        onClick={() => setOpen(false)}
      >
        B2C
      </LinkDiv>
    </NavigationContainer>
  );
}

const leftOpen = keyframes` 
0% {
  -webkit-transform: translateX(-260px);
          transform: translateX(-260px);
}
100% {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}`;

const leftClose = keyframes` 
0% {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
100% {
  -webkit-transform: translateX(-260px);
          transform: translateX(-260px);
}`;

const NavigationContainer = styled.nav`
  z-index: 9999;
  position: fixed;
  width: 260px;
  top: 0;
  height: 100%;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(203, 32, 88, 0.16);
  animation: ${(props) => (props.open ? leftOpen : leftClose)} 0.3s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;
const LogoDiv = styled.div`
  padding: 50px 30px;
`;
const Img = styled.img``;

const ArrowIcon = styled(Arrow)`
  fill: ${(props) => (props.color ? props.color : "white")};
  transform: ${(props) =>
    props.$rotate ? (props.open ? "rotate(-90deg)" : "rotate(90deg)") : ""};
`;

const Label = styled.div`
  display: flex;
  height: 62px;
  align-items: center;
  justify-content: space-between;
  color: #cb2058;
  font-size: 20px;
  font-weight: 700;
  box-sizing: border-box;
  padding-right: 16px;

  & > p {
    width: 100%;
    margin-left: 26px;
    box-sizing: border-box;
  }
`;

const LabelLine = styled.div`
  width: 4px;
  height: 100%;
  background-color: #cb2058;
`;

const BtnImg = styled.div`
  cursor: pointer;
  width: 20px;
  height: 40px;
  background-color: #cb2058;
  border-radius: 0 20px 20px 0px;
  box-shadow: 4px 0px 10px rgba(203, 32, 88, 0.16);
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
`;

const LinkDiv = styled(Link)`
  display: block;
  padding: 10px 0 10px 46px;
  box-sizing: border-box;
  width: 100%;
  color: #aeb0b3;
  font-size: 18px;

  ${(props) =>
    props.selected &&
    css`
      font-weight: 700;
      color: #cb2058;
      background-color: rgba(203, 32, 88, 0.06);
    `}

  &:hover {
    background-color: rgba(203, 32, 88, 0.06);
  }
`;
export default withRouter(Navigation);

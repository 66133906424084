import React, { useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import _ from "lodash";
import { getYear, getMonth } from "date-fns";
import Select from "./Select";
import Input from "./Input";

import "../../styles/datepicker.css";

const years = _.range(1990, getYear(new Date()) + 1, 1);
const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

const renderCustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <div
    style={{
      margin: 10,
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    {/* eslint-disable-next-line react/button-has-type */}
    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
      {"<"}
    </button>

    <div>
      <select
        style={{ marginRight: "5px" }}
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        style={{ marginLeft: "5px" }}
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>

    {/* eslint-disable-next-line react/button-has-type */}
    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
      {">"}
    </button>
  </div>
);

function FilterSelect({ selectList, selectData, setSelectData, name, label }) {
  const onChange = (event) => {
    const { value } = event.target;
    setSelectData({ ...selectData, [name]: value });
  };

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  return (
    <FilterSelectDiv flex={name === "date" ? 2 : 1}>
      <Label>{label}</Label>
      {name === "date" && (
        <DateDiv>
          <DatePicker
            selected={startDate}
            dateFormat="yyyy/MM/dd"
            onChange={(d) => {
              setStartDate(d);
              if (d) {
                setSelectData({
                  ...selectData,
                  startDate: moment(d).format("YYYY-MM-DD"),
                });
              } else {
                setSelectData({
                  ...selectData,
                  startDate: "",
                });
              }
            }}
            renderCustomHeader={renderCustomHeader}
            showYearDropdown
            showMonthDropdown
          />
          ~
          <DatePicker
            selected={endDate}
            dateFormat="yyyy/MM/dd"
            onChange={(d) => {
              setEndDate(d);
              if (d) {
                setSelectData({
                  ...selectData,
                  endDate: moment(d).format("YYYY-MM-DD"),
                });
              } else {
                setSelectData({
                  ...selectData,
                  endDate: "",
                });
              }
            }}
            renderCustomHeader={renderCustomHeader}
            showYearDropdown
            showMonthDropdown
          />
        </DateDiv>
      )}
      {selectList && (
        <Select
          selectList={selectList}
          selectData={selectData}
          setSelectData={setSelectData}
          name={name}
        />
      )}
    </FilterSelectDiv>
  );
}

const FilterSelectDiv = styled.div`
  box-sizing: border-box;
  flex: ${(props) => props.flex};
  max-width: ${(props) => (props.flex === 2 ? "360px" : "166px")};
  font-size: 12px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #5a5b5e;
  margin-bottom: 4px;
  padding-left: 2px;
`;

const DateDiv = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  height: 34px;
`;

const TimeDiv = styled.div`
  height: 34px;
`;
export default FilterSelect;

/* eslint-disable no-alert */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import moment from "moment-timezone";
import B2CAddModal from "./B2CAddModal";
import B2CTable from "./B2CTable";
import FilterSelect from "./FilterSelect";
import Input from "./Input";
import Pagenation from "./Pagenation";
import fileDownloader from "../../modules/FileDownloader";
import Modal from "./Modal";
import DelModal from "./DelModal";

function B2C(props) {
  const [selectData, setSelectData] = useState({
    lang: "",
    startDate: "",
    startTime: "",
  });

  // modal

  const [modalOpen, setModalOpen] = useState(false);
  const [item, setItem] = useState({});
  const timeList = [
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "19:00",
  ];

  const startTimeList = ["10:00 1회차", "11:00 2회차", "19:00 3회차"];

  const editItem = () => {
    setModalOpen(true);
    setItem({});
  };

  const modalClose = () => {
    setModalOpen(false);
  };

  const inputFileRef = useRef(null);
  const [isUploading, setUploading] = useState(false);
  const uploadClickHandler = () => {
    inputFileRef.current.click();
  };
  const handleFileOnChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      if (file && !isUploading) {
        setUploading(true);
        const fd = new FormData();
        fd.append("excel", file);
        axios
          .post(
            `https://lginnovationgallery.com/api/admin/basic/upload/excel`,
            fd
          )
          .then(() => {
            alert("업로드 되었습니다.");
            setUploading(false);
            getList();
          })
          .catch((error) => {
            setUploading(false);
            console.log(error);
            alert("업로드에 실패했습니다.");
          });
      }
    };
    if (file) reader.readAsDataURL(file);
  };

  const handleFileDownload = () => {
    axios
      .get(
        `https://lginnovationgallery.com/api/admin/basic/download/excel?${filter.replace(
          "&",
          ""
        )}${search ? `&keyword=${search}` : ""}`,
        {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          responseType: "blob",
        }
      )
      .then(async (res) => {
        const fileName = `download.xlsx`;
        fileDownloader(res.data, fileName);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFileDownload2 = () => {
    console.log();
    axios
      .get(
        `https://lginnovationgallery.com/api/admin/basic/download/excel/targets?reservationIds=${checkItems.toString()}`,
        {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          responseType: "blob",
        }
      )
      .then(async (res) => {
        const fileName = `download.xlsx`;
        fileDownloader(res.data, fileName);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 15개 마다 pagenation
  const [list, setList] = useState([]);
  const [langFilterList, setLangFilterList] = useState([]);
  const [filter, setFilter] = useState("");
  const [filters, setFilters] = useState({
    perPage: 15,
    page: 1,
    lastRowNum: 0,
  });
  const [pages, setPages] = useState({
    currentCount: 0,
    currentPage: 1,
    perPage: 15,
    totalPages: 1,
  });
  const filtering = () => {
    let filterTemp = [];
    for (const key of Object.keys(selectData)) {
      if (selectData[key]) {
        if (key.includes("Time")) {
          filterTemp = [
            ...filterTemp,
            `&${key}=${selectData[key].replaceAll(":", "").split(" ", 1)}`,
          ];
        } else {
          filterTemp = [...filterTemp, `&${key}=${selectData[key]}`];
        }
      }
    }

    setSearch("");
    setFilter(filterTemp.toString().replaceAll(",", ""));
    setFilters({ ...filters, page: 1 });
  };

  const [flag, setFlag] = useState(false);
  const getList = async () => {
    if (!flag) {
      setFlag(true);
      await axios
        .get(
          `https://lginnovationgallery.com/api/admin/basic?lastRowNum=${
            filters.lastRowNum
          }&perPage=15&page=${filters.page}${filter || ""}${
            search ? `&keyword=${search}` : ""
          }`
        )
        .then((res) => {
          const { result } = res.data;
          setList(result.list);
          setLangFilterList(result.langFilterList);
          setPages({
            currentCount: result.currentCount,
            currentPage: result.currentPage,
            perPage: result.perPage,
            totalPages: result.totalPages,
            totalCount: result.totalCount,
          });
          setFlag(false);
        })
        .catch((error) => {
          console.log(error.response);
          setFlag(false);
        });
    }
  };

  useEffect(() => {
    getList();
  }, [filter, filters, pages]);

  const [checkItems, setCheckItems] = useState([]);
  const [search, setSearch] = useState("");

  const changeSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const onEnterPress = (e) => {
    if (search.replace(/(^\s*)|(\s*$)/gi, "") !== "") {
      if (e.keyCode === 13 && e.shiftKey === false && search) {
        e.preventDefault();
        searching();
      }
    }
  };

  const searching = () => {
    getList();
  };

  // 추가하기

  const saveAddItem = async () => {
    const params = {
      ...item,
      startDt: item.startDt
        ? `${moment(item.startDt).format("YYYY-MM-DD")} ${String(
            item.startTimePoint
          ).split(" ", 1)}`
        : "",
      endDt: item.endDt
        ? `${moment(item.startDt).format("YYYY-MM-DD")} ${String(
            item.startTimePoint
          ).split(" ", 1)}`
        : "",
      reservedDt: item.reservedDt
        ? `${moment(item.reservedDt).format("YYYY-MM-DD")} ${String(
            item.reservedTime
          )
            .replace(/\s/gi, "")
            .replace(/(\d{2})(\d{2})/, "$1:$2")}`
        : "",
      visitorMount1: item.visitorMount1 ? String(item.visitorMount1) : "0",
      visitorMount2: item.visitorMount2 ? String(item.visitorMount2) : "0",
      visitorMount3: item.visitorMount3 ? String(item.visitorMount3) : "0",
      visitorMount4: item.visitorMount4 ? String(item.visitorMount4) : "0",
      visitorMount5: item.visitorMount5 ? String(item.visitorMount5) : "0",
    };
    if (!flag) {
      setFlag(true);
      await axios
        .post(`https://lginnovationgallery.com/api/admin/basic/create`, params)
        .then((res) => {
          console.log(res.data);
          setTimeout(() => {
            setFlag(false);
            modalClose();
            window.location.reload();
          }, []);
        })
        .catch((error) => {
          console.log(error);
          setTimeout(() => {
            setFlag(false);
          }, []);
        });
    }
  };

  const onChange = (event) => {
    const { value, name } = event.target;
    setItem({ ...item, [name]: value });
  };

  // deleteitem

  const [delOpen, setDelOpen] = useState(false);

  const deleteModal = () => {
    if (checkItems.length > 0) {
      setDelOpen(true);
    } else {
      setDelOpen(false);
    }
  };
  const closemodal = () => {
    setDelOpen(false);
  };

  const deleteItem = async () => {
    await axios
      .delete(`https://lginnovationgallery.com/api/admin/basic/delete`, {
        params: {
          reservationIds: checkItems,
        },
      })
      .then((res) => {
        setDelOpen(false);
        getList();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <>
      {modalOpen && (
        <Modal open={modalOpen} close={modalClose} onClick={saveAddItem}>
          <B2CAddModal
            onChange={onChange}
            item={item}
            setItem={setItem}
            timeList={timeList}
            startTimeList={startTimeList}
          />
        </Modal>
      )}
      <FliterDiv>
        <FilterSelect
          selectList={langFilterList}
          selectData={selectData}
          setSelectData={setSelectData}
          name="lang"
          label="언어"
        />
        <FilterSelect
          label="연-월-일"
          name="date"
          selectData={selectData}
          setSelectData={setSelectData}
        />
        <FilterSelect
          label="시작 시간"
          name="startTime"
          selectList={startTimeList}
          selectData={selectData}
          setSelectData={setSelectData}
        />
        <FilterBtn onClick={filtering}>적용</FilterBtn>
      </FliterDiv>
      <ExcelDiv>
        <ExcelFilterDiv>
          <InvisibleInput
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={handleFileOnChange}
            ref={inputFileRef}
          />
          <ExcelButton color="#77797c" onClick={uploadClickHandler}>
            엑셀 업로드
          </ExcelButton>
          <ExcelButton color="#5A5B5E" onClick={handleFileDownload}>
            엑셀 전체 다운로드
          </ExcelButton>
          <ExcelButton color="#5A5B5E" onClick={handleFileDownload2}>
            엑셀 선택 다운로드
          </ExcelButton>
          <SearchBox>
            <Input
              value={search}
              onChange={changeSearch}
              onKeyDown={onEnterPress}
            />
          </SearchBox>
          <SearchBtn onClick={searching}>검색</SearchBtn>
        </ExcelFilterDiv>
        <ModifiyBtn onClick={editItem}>추가</ModifiyBtn>
        {delOpen && (
          <DelModal
            open={delOpen}
            closemodal={closemodal}
            deleteItem={deleteItem}
          />
        )}
        <DeleteBtn onClick={deleteModal}>삭제</DeleteBtn>
      </ExcelDiv>
      <B2CTable
        list={list}
        checkItems={checkItems}
        setCheckItems={setCheckItems}
        timeList={timeList}
        startTimeList={startTimeList}
        langFilterList={langFilterList}
      />
      <Pagenation filters={filters} setFilters={setFilters} pages={pages} />
    </>
  );
}

const FliterDiv = styled.div`
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  gap: 10px;
  width: 100%;
`;

const FilterBtn = styled.button`
  background-color: #cb2058;
  color: white;
  width: 60px;
  height: 34px;
  text-align: Center;
  border-radius: 2px;
`;

const ExcelDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 26px;
  margin-top: 50px;
`;

const ExcelFilterDiv = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const SearchBox = styled.div`
  height: 100%;
  margin-left: 4px;
  margin-right: 6px;
  font-size: 10px;
`;

const SearchBtn = styled.button`
  background-color: #cb2058;
  color: white;
  width: 48px;
  height: 26px;
  text-align: center;
  border-radius: 2px;
  font-size: 10px;
`;

const ExcelButton = styled.button`
  height: 100%;
  box-sizing: border-box;
  background-color: ${(props) => props.color};
  color: white;
  font-size: 10px;
  margin-right: 6px;
  padding: 0 7px;
  border-radius: 2px;
  border: 1px solid rgba(174, 176, 179, 0.25);
`;

const DeleteBtn = styled.button`
  background-color: #aeb0b3;
  color: white;
  width: 48px;
  height: 26px;
  text-align: center;
  border-radius: 2px;
  font-size: 10px;
`;

const ModifiyBtn = styled(DeleteBtn)`
  background-color: #cb2058;
  margin-right: 10px;
`;

const InvisibleInput = styled.input`
  display: none;
`;

export default B2C;

import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import axios from "axios";
import moment from "moment";
import timeicon from "../../assets/icon/step2.svg";
import qr from "../../assets/icon/step2qr.svg";
import "moment/locale/ko";
import line from "../../assets/icon/dashed.svg";

function Step2({ setStep, selectedDate, setItem, setSelectedData }) {
  const [loading, setLoading] = useState(true);
  const home = () => {
    setStep("date");
  };

  const [vipList, setVipList] = useState([]);
  const [basicList, setBasicList] = useState([]);

  const getList = async (select) => {
    const date = moment(select).format("YYYY-MM-DD");

    await axios
      .get(`https://lginnovationgallery.com/api/client/timetable?date=${date}`)
      .then((res) => {
        const list = res.data.result;
        const vip = list.filter((el) => el.type === "vip");
        const basic = list.filter((el) => el.type === "basic");
        setVipList(vip);
        setBasicList(basic);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    if (selectedDate) {
      setItem({});
      getList(selectedDate);
    } else {
      window.location.reload();
    }

    return () => setLoading(true);
  }, [selectedDate]);

  const onClicked = (item) => {
    setSelectedData(item);
    setTimeout(() => {
      if (item.type === "vip") {
        setStep("visitor");
      } else {
        setStep("reservationList");
      }
    }, [300]);
  };

  const onQrCheck = () => {
    setStep("qr");
  };

  return (
    !loading && (
      <Container>
        <Date onClick={home}>
          {moment(selectedDate).format("YY.MM.DD (dd)")}
        </Date>
        <Icon src={timeicon} />
        <Title>
          <span>예약하신 투어시간</span>을 선택해주세요
        </Title>
        <ListBox>
          <ListBoxDiv>
            {vipList?.map((item, index) => (
              <List
                key={`item${index}`}
                isRegistered={item.is_registered}
                onClick={() => {
                  onClicked(item);
                }}
              >
                <ReservationTime>{item.start_time}</ReservationTime>
                <ListText>{item.title}</ListText>
              </List>
            ))}
          </ListBoxDiv>
          {basicList.length > 0 ? (
            <>
              <Line src={line} />
              <ListBoxDiv>
                {basicList.map((basic, index) => (
                  <List
                    key={`basic${index}`}
                    onClick={() => {
                      onClicked(basic);
                    }}
                  >
                    <ReservationTime>
                      {basic.start_time}{" "}
                      {(basic.start_time.includes("12") || basic.start_time.includes("10"))
                        ? "1회차"
                        : (basic.start_time.includes("18") || basic.start_time.includes("11"))
                        ? "2회차"
                        : "3회차"}
                    </ReservationTime>
                    <ListText>{basic.title}</ListText>
                  </List>
                ))}
              </ListBoxDiv>
            </>
          ) : (
            <></>
          )}
        </ListBox>
        <StepBox>
          <span />
          <span />
          <span />
          <span />
        </StepBox>
        <QrDiv onClick={onQrCheck}>
          <QrIcon src={qr} />
        </QrDiv>
      </Container>
    )
  );
}

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Date = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 150px;
  height: 36px;
  background: #aeb0b3;
  box-shadow: 0px 3px 3px rgb(90 91 94 / 26%);
  border-radius: 16px;
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 16px;
  cursor: pointer;
`;

const Icon = styled.img`
  margin-top: 95px;
`;

const QrIcon = styled.img`
`;

const Title = styled.p`
  margin-top: 20px;
  font-size: 24px;
  font-weight: 500;
  color: #5a5b5e;

  & > span {
    font-weight: 700;
  }
`;

const ListBox = styled.div`
  height: 420px;
  display: grid;
  overflow-y: scroll;
  padding: 40px 10px 74px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ListBoxDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const List = styled.button`
  width: 210px;
  height: 150px;
  padding: 10px;
  border: 1px solid #fcfcfc;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 2px 10px 20px rgba(90, 91, 94, 0.1);
  font-size: 18px;
  text-align: center;
  color: #77797c;

  &:active,
  &:hover,
  &:focus {
    background: #cb2058;
    color: #fff;
    font-weight: 700;
    border: 0px solid rgb(255 255 255 /10%);
    box-shadow: 8px 10px 20px rgb(90 91 94 / 26%);
  }
  color: ${(props) => (props.isRegistered === 1 ? "#cb2058" : "#77797c")};
  box-shadow: ${(props) =>
    props.isRegistered === 1
      ? "2px 10px 20px inset rgb(90 91 94 / 12%)"
      : "2px 10px 20px rgb(90 91 94 / 10%)"};
  cursor: pointer;
`;

const ListText = styled.div`
  white-space: pre-wrap;
`;

const ListTextBasic = styled.div``;

const ReservationTime = styled.span`
  display: block;
  font-size: 20px;
`;

const StepBox = styled.div`
  width: 106px;
  height: 22px;

  & > span {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    display: block;
    background: #fff;
    float: left;
    margin-right: 10px;
    box-shadow: 0px 2px 4px inset rgb(90 91 94 / 12%);

    &:nth-of-type(1) {
      background: #cb2058;
      border: 1px solid rgb(255 255 255 /10%);
      box-shadow: -3px 4px 7px 2px rgb(203 32 88 / 26%);
      box-sizing: border-box;
    }

    &:nth-of-type(4) {
      margin-right: 0;
    }
  }
`;

const QrDiv = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
`;

const Line = styled.img``;

export default Step2;

import React, { useEffect } from "react";
import styled from "styled-components";
import closeBtn from "../../assets/icon/close.svg";

function Modal({ children, open, close, onClick }) {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: overlay;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);
  return (
    <ModalDiv isOpen={open}>
      <ModalContentDiv>
        <ModalClose>
          <Close onClick={close} />
        </ModalClose>
        <Content>
          {children}
          <ConfirmBtn onClick={onClick}>저장</ConfirmBtn>
        </Content>
      </ModalContentDiv>
    </ModalDiv>
  );
}

const ModalDiv = styled.div`
  z-index: 9999;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  position: fixed;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(25, 25, 25, 0.6);
  align-items: center;
  justify-content: center;
`;

const ModalContentDiv = styled.div`
  position: relative;
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: white;
  max-height: 90%;
  overflow-y: overlay;
  padding: 20px;
  width: 640px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ModalClose = styled.div`
  width: 100%;
  height: 14px;
  display: flex;
  justify-content: flex-end;
`;

const Close = styled.button`
  width: 14px;
  height: 14px;
  background-image: url(${closeBtn});
  background-repeat: no-repeat;
  background-size: contain;
`;

const Content = styled.div`
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
`;
const Label = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #cb2058;
  margin-bottom: 20px;
`;

const ConfirmBtn = styled.button`
  display: block;
  background-color: #cb2058;
  color: white;
  width: 200px;
  height: 34px;
  text-align: center;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 700;
  margin: 0 auto;
  margin-top: 40px;
`;
export default Modal;

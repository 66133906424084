/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import moment from "moment-timezone";
import axios from "axios";
import Checkbox from "./CheckBox";
import Modal from "./Modal";
import ModiModal from "./ModiModal";

function B2BTable({
  list,
  filterList,
  programList,
  langFilterList,
  interestingList,
  timeList,
  checkItems,
  setCheckItems,
  companyList,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectItem, setSelectItem] = useState({});

  const editItem = (item) => {
    setModalOpen(true);
    setSelectItem(item);
  };
  const modalClose = () => {
    setModalOpen(false);
  };

  const [flag, setFlag] = useState(false);

  const saveItem = async () => {
    const params = {
      name: selectItem.name,
      startDt: `${moment(selectItem.start_dt).format("YYYY-MM-DD")} ${String(
        selectItem.start_time
      )
        .replace(/\s/gi, "")
        .replace(/(\d{2})(\d{2})/, "$1:$2")}`,
      endDt: `${moment(selectItem.end_dt).format("YYYY-MM-DD")} ${String(
        selectItem.end_time
      )
        .replace(/\s/gi, "")
        .replace(/(\d{2})(\d{2})/, "$1:$2")}`,
      reservedDt: `${moment(selectItem.reserved_dt).format(
        "YYYY-MM-DD"
      )} ${String(selectItem.reserved_time)
        .replace(/\s/gi, "")
        .replace(/(\d{2})(\d{2})/, "$1:$2")}`,
      lang: selectItem.lang,
      title: selectItem.title,
      visitorMount1: String(selectItem.visitor_mount_1),
      visitorMount2: String(selectItem.visitor_mount_2),
      company: selectItem.company,
      companyNote: selectItem.company_note,
      team: selectItem.team,
      position: selectItem.position,
      visitorType: selectItem.visitor_type,
      visitPurpose: selectItem.visit_purpose,
      info: selectItem.info,
      interesting: selectItem.interesting.toString(),
      program: selectItem.program,
    };
    if (!flag) {
      setFlag(true);
      await axios
        .put(
          `https://lginnovationgallery.com/api/admin/vip/edit/${selectItem.id}`,
          params
        )
        .then((res) => {
          setTimeout(() => {
            setFlag(false);
            modalClose();
            window.location.reload();
          }, []);
        })
        .catch((error) => {
          console.log(error.response);
          setTimeout(() => {
            setFlag(false);
          }, []);
        });
    }
  };

  const handleAllCheck = (checked) => {
    if (checked) {
      let temp = [];
      list.map((item) => (temp = [...temp, item.id]));
      setCheckItems(temp);
    } else {
      setCheckItems([]);
    }
  };

  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheckItems([...checkItems, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  return (
    <>
      {modalOpen && (
        <Modal open={modalOpen} close={modalClose} onClick={saveItem}>
          <ModiModal
            selectItem={selectItem}
            setSelectItem={setSelectItem}
            filterList={filterList}
            programList={programList}
            langFilterList={langFilterList}
            interestingList={interestingList}
            timeList={timeList}
            companyList={companyList}
          />
        </Modal>
      )}
      <TableContainer>
        <TableInner>
          <TableRow header>
            <Col width={30}>
              <label>
                <Checkbox
                  onChange={(e) => handleAllCheck(e.target.checked)}
                  checked={checkItems.length === list.length}
                />
              </label>
            </Col>
            <Col width={200}>제목</Col>
            <Col width={50}>언어</Col>
            <Col width={100}>요청자</Col>
            <Col width={200}>예약일자</Col>
            <Col>요청일</Col>
            <Col>방문인원(명)</Col>
            <Col>고객사 방문인원(명)</Col>
            <Col>LG 방문인원(명)</Col>
            <Col>회사</Col>
            <Col>비고</Col>
            <Col>팀명</Col>
            <Col>직책</Col>
            <Col>방문객유형 </Col>
            <Col>방문목적</Col>
            <Col>고객사정보</Col>
            <Col>주요 관심분야</Col>
            <Col>투어프로그램</Col>
            <Col width={60}>관리</Col>
          </TableRow>
          {list?.map((item, index) => (
            <TableRow
              key={`B2BTable${index}`}
              checked={!!checkItems.includes(item.id)}
            >
              <Col width={30}>
                <label>
                  <Checkbox
                    onChange={(e) =>
                      handleSingleCheck(e.target.checked, item.id)
                    }
                    checked={!!checkItems.includes(item.id)}
                  />
                </label>
              </Col>
              <Col width={200}>
                <p>{item.title}</p>
              </Col>
              <Col width={50}>{item.lang}</Col>
              <Col width={100}>
                <p>{item.name}</p>
              </Col>
              <Col width={200}>
                {`${moment(item.start_dt)
                  .utc()
                  .format("YYYY-MM-DD HH:mm")} ~ ${moment(item.end_dt)
                  .utc()
                  .format("YYYY-MM-DD HH:mm")}`}
              </Col>
              <Col>
                {item.reserved_dt &&
                  moment(item.reserved_dt).utc().format("YYYY-MM-DD HH:mm")}
              </Col>
              <Col>
                {item.total_mount}
                <>
                  {item.is_registered === 1 && (
                    <span>&nbsp;{`| ${item.registered_total_mount}`}</span>
                  )}
                </>
              </Col>
              <Col>
                {item.visitor_mount_2}
                <>
                  {item.is_registered === 1 && (
                    <span>&nbsp;{`| ${item.registered_visitor_mount_2}`}</span>
                  )}
                </>
              </Col>
              <Col>
                {item.visitor_mount_1}
                <>
                  {item.is_registered === 1 && (
                    <span>&nbsp;{`| ${item.registered_visitor_mount_1}`}</span>
                  )}
                </>
              </Col>
              <Col>{item.company}</Col>
              <Col>{item.company_note}</Col>
              <Col>{item.team}</Col>
              <Col>{item.position}</Col>
              <Col line={2}>
                <p>
                  {item.visitor_type}
                  <>
                    {item.is_registered === 1 && (
                      <span>{`| ${item.registered_visitor_type}`}</span>
                    )}
                  </>
                </p>
              </Col>
              <Col line={2}>
                {item.visit_purpose}
                <>
                  {item.is_registered === 1 && (
                    <span>{`| ${item.registered_visit_purpose}`}</span>
                  )}
                </>
              </Col>
              <Col>
                <p>{item.info}</p>
              </Col>
              <Col>
                <p>
                  {item.interesting.split(",").length >= 10
                    ? "전체"
                    : item.interesting}
                </p>
              </Col>
              <Col>{item.program}</Col>
              <Col width={60} onClick={() => editItem(item)}>
                <EditBtn>수정</EditBtn>
              </Col>
            </TableRow>
          ))}
        </TableInner>
      </TableContainer>
    </>
  );
}

const TableContainer = styled.div`
  width: auto;
  overflow-x: auto;
  margin-top: 6px;
  color: #5a5b5e;
  font-size: 10px;
`;

const TableInner = styled.div`
  min-width: fit-content;
  width: 100%;
`;

const Col = styled.div`
  padding: 0 4px;
  width: ${(props) => (props.width ? `${props.width}px` : "130px")};
  height: 100%;
  display: flex;
  flex-direction: ${(props) => (props.line === 2 ? "column" : "row")};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;

  & > span {
    color: #cb2058;
  }

  & > p {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;
  }
`;

const TableRow = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  background: ${(props) => (props.checked ? "#F7DCE5" : "unset")};

  ${(props) =>
    props.header
      ? `
      & > ${Col}{
        background-color: rgba(174, 179, 178, 0.26);
        border-top: 1px solid rgba(174, 179, 178, 0.35);
        border-bottom: 1px solid rgba(174, 179, 178, 0.35);
        height: 24px;
        font-weight: 700;
       }
        `
      : css`
          height: 44px;
        `};
`;

const EditBtn = styled.button`
  background-color: #cb2058;
  color: white;
  width: 48px;
  height: 26px;
  text-align: center;
  border-radius: 2px;
  font-size: 10px;
`;

export default B2BTable;

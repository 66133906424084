import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useCookies } from "react-cookie";
import bg from "../../assets/icon/loginbg.jpg";

function Login({ history }) {
  const [cookies, setCookie, removeCookie] = useCookies(["isLogin"]);
  const ids = [
    "lggallery1",
    "lggallery2",
    "lggallery3",
    "lggallery4",
    "lgcns",
    "test1",
    "test2",
    "differoute",
  ];
  const password = "at5789?";
  const password1 = "lgcns";

  const accounts = [
    { id: "lggallery1", pw: "dlshrof2431?" },
    { id: "lggallery2", pw: "dlshrof2432?" },
    { id: "lggallery3", pw: "dlshrof2433?" },
    { id: "lggallery4", pw: "dlshrof2434?" },
    { id: "lggallery_sni_1", pw: "tpfvmA1" },
    { id: "lggallery_sni_2", pw: "tpfvmB2" },
    { id: "lgcns", pw: "lgcns" },
    { id: "test1", pw: "lgTest123?" },
    { id: "test2", pw: "lgTest223?" },
    { id: "differoute", pw: "lgDiff333?" },
  ];

  const [account, setAccount] = useState({
    id: "",
    password: "",
  });
  const [invlid, setInvalid] = useState(false);

  const onChangeAccount = (e) => {
    const { name, value } = e.target;
    setAccount({
      ...account,
      [name]: value,
    });
    setInvalid(false);
  };
  const onSubmit = () => {
    const target = accounts.find((i) => i.id === account.id);
    if (target && target.pw === account.password) {
      setCookie("isLogin", true, { path: "/" });
      setTimeout(() => {
        history.push("/admin/b2b");
      }, []);
    } else {
      setInvalid(true);
      setAccount({
        id: "",
        password: "",
      });
    }
  };

  return (
    <LoginWrap>
      <Wrap>
        <Container>
          <Text>로그인</Text>
          <Label>아이디</Label>
          <Input
            id="id"
            name="id"
            value={account.id}
            onChange={onChangeAccount}
            bottom="20px"
          />
          <Label>비밀번호</Label>
          <Input
            id="password"
            name="password"
            value={account.password}
            type="password"
            onChange={onChangeAccount}
            bottom="7px"
          />
          <HelperText invlid={invlid}>
            아이디 또는 비밀번호가 잘못 입력 되었습니다.
          </HelperText>
          <Button
            disabled={!account.id || !account.password}
            onClick={onSubmit}
          >
            로그인
          </Button>
        </Container>
      </Wrap>
    </LoginWrap>
  );
}

const LoginWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
  background: url(${bg}) no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  backdrop-filter: blur(10px) brightness(60%);
`;

const Container = styled.div`
  width: 250px;
  height: 332px;
  background: white;
  padding: 0 50px;
  border-radius: 8px;
  border: 1px solid rgb(174 176 179 / 50%);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Text = styled.p`
  font-size: 22px;
  font-weight: bold;
  color: #cb2058;
  margin: 54px 0 20px 0;
  text-align: center;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #5a5b5e;
`;
const Input = styled.input`
  position: relative;
  overflow: hidden;
  width: 250px;
  height: 34px;
  border: solid 1px rgb(174 176 179 / 25%);
  border-radius: 2px;
  background: #fff;
  box-sizing: border-box;
  margin-bottom: ${(props) => props.bottom && props.bottom};
`;

const HelperText = styled.p`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 7px;
  visibility: ${({ invlid }) => (invlid ? "visible" : "hidden")};
  color: #cb2058;
`;

const Button = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 49px;
  display: block;
  width: 250px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  border: none;
  background-color: #cb2058;
  ${({ disabled }) =>
    disabled &&
    `
    background-color: #AEB0B3;
  `}
`;
export default withRouter(Login);

import React from "react";
import styled from "styled-components";
import logo from "../../assets/icon/logo.svg";

function Logotest({ setStep, step }) {
  const onClicked = () => {
    if (step === "date") {
      setStep("date");
    } else {
      setStep("list");
    }
  };
  return (
    <div>
      <Logo onClick={onClicked} />
    </div>
  );
}

const Logo = styled.div`
  background-image: url(${logo});
  display: block;
  width: 157px;
  height: 28px;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
`;

export default Logotest;

import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import _ from "lodash";
import { getYear, getMonth } from "date-fns";
import moment from "moment-timezone";
import Input from "./Input";
import Select from "./Select";
import { ReactComponent as Icon } from "../../assets/icon/drop.svg";
import "../../styles/datepicker.css";

const years = _.range(1990, getYear(new Date()) + 1, 1);
const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

const renderCustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <div
    style={{
      margin: 10,
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    {/* eslint-disable-next-line react/button-has-type */}
    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
      {"<"}
    </button>

    <div>
      <select
        style={{ marginRight: "5px" }}
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        style={{ marginLeft: "5px" }}
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>

    {/* eslint-disable-next-line react/button-has-type */}
    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
      {">"}
    </button>
  </div>
);

function AddFilter({
  label,
  selectList,
  name,
  item,
  setItem,
  value,
  placeholder,
}) {
  const setDate = (d) => {
    // 시작 예약일자 선택 시 종료 예약 일자와 동일하게 세팅
    if (name === "start_dt") {
      setItem({ ...item, start_dt: d, end_dt: d });
    } else if (name === "startDt") {
      setItem({ ...item, startDt: d, endDt: d });
    } else {
      setItem({ ...item, [name]: d });
    }
  };

  return (
    <AddFilterSelectDiv
      flex={name.toLowerCase().includes("reserved") ? 0.316 : 1}
    >
      <Label>{label}</Label>
      {name?.toLowerCase().includes("dt") &&
      !name?.toLowerCase().includes("time") ? (
        <AddDateDiv>
          <DatePicker
            selected={value ? new Date(value) : ""}
            dateFormat="yyyy/MM/dd"
            onChange={(d) => {
              setDate(d);
            }}
            renderCustomHeader={renderCustomHeader}
            showYearDropdown
            showMonthDropdown
          />
        </AddDateDiv>
      ) : (
        <Select
          selectList={selectList}
          selectData={item}
          setSelectData={setItem}
          name={name}
          placeholder={placeholder || "선택"}
          exceptAll
        />
      )}
    </AddFilterSelectDiv>
  );
}

const AddFilterSelectDiv = styled.div`
  box-sizing: border-box;
  flex: ${(props) => props.flex};
  max-width: ${(props) => (props.flex === 2 ? "360px" : "275px")};
  font-size: 12px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #5a5b5e;
  margin-bottom: 4px;
  padding-left: 2px;
`;

const AddDateDiv = styled.div`
  display: flex;
  margin-right: 8px;
  align-items: center;
`;

export default AddFilter;

/* eslint-disable no-plusplus */
import React from "react";
import styled, { css } from "styled-components";
import arrow from "assets/icon/arrow.svg";

function Pagenation({ filters, setFilters, pages }) {
  const changePage = (i) => {
    if (i >= 0 && i <= pages.totalPages && pages.currentPage !== i) {
      setFilters({
        ...filters,
        page: i,
        lastRowNum: filters.perPage * (i - 1),
      });
    }
  };

  const currentGroup = Math.floor((pages.currentPage - 1) / 5);
  const pageNum = () => {
    const forArray = [];
    const lastIndex =
      pages.totalPages > currentGroup * 5 + 5
        ? currentGroup * 5 + 5
        : pages.totalPages;
    for (let i = currentGroup * 5; i < lastIndex; i++) {
      forArray.push(
        <NumberButton
          key={i}
          current={pages.currentPage === i + 1}
          onClick={() => {
            changePage(i + 1);
          }}
        >
          {i + 1}
        </NumberButton>
      );
    }
    return forArray;
  };

  return (
    <RowDiv>
      {Math.floor(currentGroup) !== 0 && (
        <ArrowButton
          onClick={() => {
            changePage((currentGroup - 1) * 5 + 1);
          }}
        >
          <Img src={arrow} />
        </ArrowButton>
      )}
      <NumberDiv align="center" justify="center" gap={6} $wrap>
        {pageNum()}
      </NumberDiv>
      {pages.totalPages > currentGroup * 5 + 5 && (
        <ArrowButton onClick={() => changePage(currentGroup * 5 + 6)}>
          <Img src={arrow} direction="next" />
        </ArrowButton>
      )}
    </RowDiv>
  );
}

const Img = styled.img`
  transform: ${({ direction }) =>
    direction === "next" ? "rotate(90deg)" : "rotate(-90deg)"};
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;x
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  gap:6px;
`;

const NumberDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const NumberButton = styled.button`
  font-size: 12px;
  border-radius: 2px;
  width: 28px;
  height: 28px;
  text-align: center;
  &:hover {
    opacity: 0.5;
  }
  ${(props) =>
    props.current
      ? css`
          color: white;
          background-color: #cb2058;
        `
      : css`
          border: 1px solid rgba(174, 176, 179, 0.26);
          background-color: white;
        `}
`;
const ArrowButton = styled.div`
  display: flex;
  font-size: 12px;
  border-radius: 2px;
  width: 28px;
  height: 28px;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgba(174, 176, 179, 0.26);
  background-color: white;

  &:hover {
    opacity: 0.5;
  }
`;
export default Pagenation;

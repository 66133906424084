import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import AddFilter from "./AddFilter";
import Input from "./Input";
import InputSelect from "./InputSelect";

function B2CAddModal({ item, setItem, startTimeList, timeList }) {
  const onChange = (e) => {
    const { value, name } = e.target;
    setItem({ ...item, [name]: value });
  };

  useEffect(() => {
    setItem({
      ...item,
      lang: "한국어",
    });
  }, []);

  return (
    <>
      <Label>추가하기</Label>
      <ModalLine>
        <ModalLabel>
          <InputLabel>예약번호</InputLabel>
          <Input
            value={item.resvNum || ""}
            name="resvNum"
            onChange={onChange}
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>언어</InputLabel>
          <TextDiv readOnly name="lang" value={item.lang || ""} />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>신청자명</InputLabel>
          <Input value={item.name || ""} name="name" onChange={onChange} />
        </ModalLabel>
      </ModalLine>
      <ModalLine>
        <AddFilter
          label="관람일시"
          name="startDt"
          item={item}
          setItem={setItem}
          onChange={onChange}
          value={item.startDt || ""}
        />
        <AddFilter
          name="startTimePoint"
          selectList={startTimeList}
          item={item}
          setItem={setItem}
          onChange={onChange}
          value={
            item.startTime
              ? String(item.startTime)
                  .replace(/\s/gi, "")
                  .replace(/(\d{2})(\d{2})/, "$1:$2")
              : ""
          }
          placeholder="00:00"
        />
        <span style={{ marginBottom: "7px" }}></span>
        <AddFilter
          label="접수일"
          name="reservedDt"
          item={item}
          setItem={setItem}
          onChange={onChange}
          value={item.reservedDt || ""}
        />
        <InputSelect
          selectList={timeList}
          selectData={item}
          setSelectData={setItem}
          value={
            item.reservedTime
              ? String(item.reservedTime)
                  .replace(/\s/gi, "")
                  .replace(/(\d{2})(\d{2})/, "$1:$2")
              : ""
          }
          name="reservedTime"
          onChange={onChange}
          style={{ maxWidth: "130px" }}
        />
      </ModalLine>
      <ModalLine>
        <ModalLabel>
          <InputLabel>총 관람인원</InputLabel>
          <TotalInput
            readOnly
            onChange={onChange}
            value={
              Number(item.visitorMount1) +
                Number(item.visitorMount2) +
                Number(item.visitorMount3) +
                Number(item.visitorMount4) +
                Number(item.visitorMount5) || ""
            }
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>임직원(LG계열사)</InputLabel>
          <Input
            type="number"
            pattern="[0-9]*"
            value={item.visitorMount1 || ""}
            name="visitorMount1"
            onChange={onChange}
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>일반 성인</InputLabel>
          <Input
            type="number"
            pattern="[0-9]*"
            value={item.visitorMount2 || ""}
            name="visitorMount2"
            onChange={onChange}
          />
        </ModalLabel>
      </ModalLine>
      <ModalLine>
        <ModalLabel>
          <InputLabel>대학생</InputLabel>
          <Input
            type="number"
            pattern="[0-9]*"
            value={item.visitorMount3 || ""}
            name="visitorMount3"
            onChange={onChange}
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>중/고등학생</InputLabel>
          <Input
            type="number"
            pattern="[0-9]*"
            value={item.visitorMount4 || ""}
            name="visitorMount4"
            onChange={onChange}
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>초등학생 이하</InputLabel>
          <Input
            type="number"
            pattern="[0-9]*"
            value={item.visitorMount5 || ""}
            name="visitorMount5"
            onChange={onChange}
          />
        </ModalLabel>
      </ModalLine>
      <ModalLine>
        <ModalLabelBox>
          <InputLabel>이메일계정</InputLabel>
          <Input value={item.email || ""} name="email" onChange={onChange} />
        </ModalLabelBox>
      </ModalLine>
    </>
  );
}

const Label = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #cb2058;
  margin-bottom: 20px;
`;

const InputLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #5a5b5e;
  margin-bottom: 4px;
  padding-left: 2px;
`;

const ModalLine = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 20px;
  justify-content: start;
  & > span {
    margin: 0 10px;
  }
`;

const ModalLabel = styled.div`
  flex: 1;
  margin-right: 10px;

  &:nth-of-type(3) {
    margin-right: 0;
  }
  & > p {
    color: #5a5b5e;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 4px;
  }
`;

const ModalLabelBox = styled(ModalLabel)`
  margin-right: 0;
`;

const TextDiv = styled.input`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: none;
  padding: 10px;
  font-size: 100%;
`;

const TotalInput = styled.input`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: none;
  padding: 10px;
  font-size: 100%;

  &:focus {
    border: unset;
  }
`;

export default B2CAddModal;

import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import personicon from "../../assets/icon/person.svg";
import "moment/locale/ko";

function Visitor({ setStep, selectedDate, item, setItem, selectedData }) {
  const home = () => {
    setStep("date");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "total_mount") {
      setItem({
        ...item,
        visitor_mount_1: "0",
        visitor_mount_2: "0",
        total_mount: "0",
      });
    } else {
      setItem({
        ...item,
        [name]: value || "0",
      });
    }
  };

  return (
    <Container>
      <Date onClick={home}>{moment(selectedDate).format("YY.MM.DD (dd)")}</Date>
      <Icon src={personicon} />
      <Title>
        <span>방문 인원 수</span>를 입력해주세요
      </Title>
      <InputContainer>
        <ContainerInner>
          <InputBox>
            <Text>LG 임직원</Text>
            <div>
              <Input
                type="number"
                pattern="[0-9]*"
                placeholder={
                  item.total_mount === "0"
                    ? 0
                    : item.visitor_mount_1 === "0"
                    ? "0"
                    : selectedData.visitor_mount_1
                }
                name="visitor_mount_1"
                onChange={handleInput}
                value={
                  item.visitor_mount_1 && item.visitor_mount_1 !== "0"
                    ? item.visitor_mount_1
                    : ""
                }
              />
              명
            </div>
          </InputBox>
          <InputBox>
            <Text>방문객</Text>
            <div>
              <Input
                type="number"
                pattern="[0-9]*"
                placeholder={
                  item.total_mount === "0"
                    ? 0
                    : item.visitor_mount_2 === "0"
                    ? "0"
                    : selectedData.visitor_mount_2
                }
                name="visitor_mount_2"
                onChange={handleInput}
                value={
                  item.visitor_mount_2 && item.visitor_mount_2 !== "0"
                    ? item.visitor_mount_2
                    : ""
                }
              />
              명
            </div>
          </InputBox>
        </ContainerInner>
        <TotalBox>
          <TotalInput>
            <div>
              총
              <Input
                type="number"
                pattern="[0-9]*"
                placeholder={selectedData.total_mount}
                name="total_mount"
                onChange={handleInput}
                value={
                  !item.visitor_mount_1 && !item.visitor_mount_2
                    ? item.total_mount || ""
                    : (
                        Number(
                          item.visitor_mount_1
                            ? item.visitor_mount_1
                            : selectedData.visitor_mount_1
                        ) +
                        Number(
                          item.visitor_mount_2
                            ? item.visitor_mount_2
                            : selectedData.visitor_mount_2
                        )
                      ).toString()
                }
              />
              명
            </div>
          </TotalInput>
        </TotalBox>
      </InputContainer>
      <BtnBox>
        <Button
          onClick={() => {
            setStep("list");
          }}
        >
          이전
        </Button>
        <NextButton
          onClick={() => {
            setStep("purpose");
          }}
        >
          다음
        </NextButton>
      </BtnBox>
      <StepBox>
        <span />
        <span />
        <span />
        <span />
      </StepBox>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 700px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Date = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 150px;
  height: 36px;
  background: #aeb0b3;
  box-shadow: 0px 3px 3px rgb(90 91 94 / 26%);
  border-radius: 16px;
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 16px;
  cursor: pointer;
`;

const Icon = styled.img`
  margin-top: 95px;
`;

const Title = styled.p`
  margin-top: 20px;
  font-size: 24px;
  font-weight: 500;
  color: #5a5b5e;

  & > span {
    font-weight: 700;
  }
`;

const InputContainer = styled.div`
  width: 700px;
  height: 334px;
  border: 1px solid rgba(174, 176, 179, 0.5);
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 40px;
  background: #fff;
`;

const ContainerInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const InputBox = styled.div`
  width: 350px;
  height: 185px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div {
    color: #5a5b5e;
    font-size: 22px;
    font-weight: 700;
  }
`;

const Text = styled.p`
  width: 230px;
  color: #5a5b5e;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
`;

const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border-radius: 10px;
  width: 200px;
  height: 60px;
  padding: 0 10px;
  margin-right: 10px;
  text-align: end;
  font-size: 22px;
  font-weight: 700;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
    border: 1px solid #cb2058;
    box-shadow: 2px 4px 10px inset rgb(203 32 88 / 12%);
    color: #cb2058;
  }

  ${(props) =>
    props.value <= 0
      ? css`
          color: #5a5b5e;
          border: 1px solid rgb(255 255 255 / 70%);
          box-shadow: 2px 4px 10px inset rgb(90 91 94 / 10%);
        `
      : css`
          color: #cb2058;
          border: 1px solid #cb2058;
          box-shadow: 2px 4px 10px inset rgb(203 32 88 / 12%);
        `};
`;

const TotalBox = styled.div`
  position: relative;
  height: 150px;

  &::before {
    content: "";
    width: 590px;
    height: 1px;
    background: #5a5b5e;
    position: absolute;
    left: 70px;
  }
`;

const TotalInput = styled(InputBox)`
  height: 150px;
  float: right;
`;

const BtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  width: 156px;
  height: 54px;
  border: 1px solid rgb(245 245 245 / 40%);
  box-shadow: 2px 10px 20px rgb(90 91 94 / 12%);
  border-radius: 10px;
  box-sizing: border-box;
  color: #aeb0b3;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  background: #fff;

  &:hover {
    color: #fff;
    background: #cb2058;
    box-shadow: 8px 10px 20px rgb(90 91 94 /26%);
    border: 0;
    font-weight: 700;
  }
`;

const NextButton = styled(Button)`
  background: ${(props) => (props.disabled ? "white" : "#cb2058")};
  color: ${(props) => (props.disabled ? "#aeb0b3" : "white")};
  box-shadow: ${(props) =>
    props.disabled
      ? "8px 10px 20px rgb(90 91 94 /26%)"
      : "2px 10px 20px rgb(90 91 94 / 12%)"};
  border: ${(props) =>
    props.disabled ? "1px solid rgb(245 245 245 / 40%)" : "0"};
  font-weight: ${(props) => (props.disabled ? "400" : "700")};
`;
const StepBox = styled.div`
  width: 106px;
  height: 22px;
  margin-top: 62px;

  & > span {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    display: block;
    background: #fff;
    float: left;
    margin-right: 10px;
    box-shadow: 0px 2px 4px inset rgb(90 91 94 / 12%);

    &:nth-of-type(2) {
      background: #cb2058;
      border: 1px solid rgb(255 255 255 /10%);
      box-shadow: -3px 4px 7px 2px rgb(203 32 88 / 26%);
      box-sizing: border-box;
    }

    &:nth-of-type(4) {
      margin-right: 0;
    }
  }
`;

export default Visitor;

import React, { useState, useCallback, useEffect, useRef } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import styled, { css } from "styled-components";
import GlobalStyle from "./styles/GlobalStyles";
import Main from "./routes/Main";
import Admin from "./routes/Admin";

function App() {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Route path="/" exact render={() => <Main />} />
        <Route path="/admin" render={() => <Admin />} />
      </BrowserRouter>
    </>
  );
}

export default App;

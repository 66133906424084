import React from "react";
import styled from "styled-components";
import moment from "moment";
import qricon from "../../assets/icon/qr220927.jpg";
import "moment/locale/ko";

function QR({ setStep, selectedDate }) {
  const home = () => {
    setStep("date");
  };
  return (
    <Container>
      <Date onClick={home}>{moment(selectedDate).format("YY.MM.DD (dd)")}</Date>
      <TextDiv>
        <Text>
          QR코드를 스캔하셔서 <br />
          투어 설문에 참여해주세요
        </Text>
        <SubText>참여해주신 분들께는 기념품을 증정해드립니다.</SubText>
      </TextDiv>
      <div>
        <Icon src={qricon} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 700px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Date = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 150px;
  height: 36px;
  background: #aeb0b3;
  box-shadow: 0px 3px 3px rgb(90 91 94 / 26%);
  border-radius: 16px;
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 16px;
  cursor: pointer;
`;

const TextDiv = styled.div`
  margin-top: 204px;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
`;

const Text = styled.div`
  color: #cb2058;
`;

const SubText = styled.div`
  font-weight: 400;
  color: #5a5b5e;
  margin: 40px 0 50px;
  font-size: 28px;
`;

const Icon = styled.img`
  margin-right: 6px;
`;

export default QR;

/* eslint-disable no-shadow */
import moment from "moment";
import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import Input from "./Input";

function InputSelect({
  selectList,
  selectData,
  setSelectData,
  name,
  value,
  onChange,
  label,
}) {
  const inputSelectRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const toggling = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (val) => () => {
    setSelectData({
      ...selectData,
      [name]: val,
    });

    setIsOpen(false);
  };

  const closeAllSelect = ({ target }) => {
    if (inputSelectRef.current) {
      if (target.contains(inputSelectRef.current)) {
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeAllSelect);

    return () => document.removeEventListener("click", closeAllSelect);
  }, []);

  return (
    <SelectDiv ref={inputSelectRef}>
      <Input
        autocomplete="off"
        onClick={toggling}
        name={name}
        value={value}
        onChange={onChange}
      />
      {isOpen && (
        <SelectOptionDiv name={name}>
          {selectList?.map((select, index) => (
            <SelectOption
              onClick={onOptionClicked(select)}
              key={`select${select}${index}`}
            >
              {select}
            </SelectOption>
          ))}
        </SelectOptionDiv>
      )}
    </SelectDiv>
  );
}

const SelectDiv = styled.div`
  position: relative;
  height: 34px;
  box-sizing: border-box;
  flex: 1;
  max-width: 275px;
  font-size: 12px;
`;

const SelectOptionDiv = styled.div`
  box-shadow: 0px 3px 8px rgba(203, 32, 88, 0.06);
  border-radius: 3px;
  z-index: 50;
  position: absolute;
  box-sizing: border-box;
  background-color: white;
  padding: 4px;
  width: 100%;
  max-height: ${(props) =>
    props.name?.toLowerCase().includes("time") ? "320px" : "204px"};
  overflow-y: overlay;
`;

const SelectOption = styled.div`
  box-sizing: border-box;
  height: 40px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: rgba(203, 32, 88, 0.06);
    transition: 0.2s ease-in-out;
  }
  ${(props) => (props.open ? "rotate(180deg)" : "rotate(0deg)")};
`;

export default InputSelect;

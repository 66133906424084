import React from "react";
import styled from "styled-components";
import moment from "moment";
import homeicon from "../../assets/icon/step7.svg";
import "moment/locale/ko";

function Done({ selectedDate, setStep, selectedData }) {
  const home = () => {
    setStep("date");
  };

  const goToList = () => {
    const t = selectedData.type;
    if (t === "vip") {
      setStep("list");
    } else if (t === "basic") {
      setStep("reservationList");
    }
  };

  return (
    <Container>
      <Date onClick={home}>{moment(selectedDate).format("YY.MM.DD (dd)")}</Date>
      <TextDiv>
        <Text>
          Self Check-In <br />
          완료되었습니다
        </Text>
        <SubText>즐거운 관람되세요!</SubText>
      </TextDiv>
      <Button onClick={goToList}>
        <Icon src={homeicon} />
        목록으로
      </Button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 700px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Date = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 150px;
  height: 36px;
  background: #aeb0b3;
  box-shadow: 0px 3px 3px rgb(90 91 94 / 26%);
  border-radius: 16px;
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 16px;
  cursor: pointer;
`;

const TextDiv = styled.div`
  margin-top: 270px;
  font-size: 40px;
  font-weight: 700;
  width: 340px;
  text-align: center;
`;

const Text = styled.div`
  color: #cb2058;
`;

const SubText = styled.div`
  font-weight: 400;
  color: #5a5b5e;
  margin: 40px 0 128px 0;
`;

const Icon = styled.img`
  margin-right: 6px;
`;
const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 64px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background: #cb2058;
  box-shadow: 8px 10px 20px rgb(90 91 94 /26%);
`;

export default Done;

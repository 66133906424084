import React from "react";
import styled, { keyframes } from "styled-components";
import moment from "moment";
import bgicon1 from "../../assets/icon/bgicon1.svg";
import bgicon2 from "../../assets/icon/bgicon2.svg";
import icon1 from "../../assets/icon/step2.svg";
import icon2 from "../../assets/icon/basicstep4.svg";
import icon3 from "../../assets/icon/person.svg";
import icon4 from "../../assets/icon/vipstep5.svg";
import icon5 from "../../assets/icon/vipstep6.svg";

function Screen({ setStep, setSelectedDate }) {
  const next = () => {
    setStep("list");
    setSelectedDate(new Date());
  };
  return (
    <Wrap>
      <Container>
        <div>
          <BgDiv></BgDiv>
          <BgDiv1></BgDiv1>
        </div>
        <MainContainer>
          <Text>
            <span>LG INNOVATION GALLERY</span>
            <P>Self Check-In</P>
          </Text>
          <Button onClick={next}>
            <span>셀프 체크인 시작하기</span>
          </Button>
          <div>
            <Icon width={27} src={icon1} />
            <Icon src={icon2} />
            <Icon width={45} src={icon3} />
            <Icon src={icon4} />
            <Icon width={45} src={icon5} />
          </div>
        </MainContainer>
      </Container>
    </Wrap>
  );
}

const rightRotate = keyframes` 0% {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
100% {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}`;

const Wrap = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  z-index: 2;
  position: absolute;
  overflow: hidden;
`;

const BgDiv = styled.div`
  position: absolute;
  top: -250px;
  left: -250px;
  width: 500px;
  height: 500px;
  background-size: cover;
  background-image: url(${bgicon2});
  background-repeat: no-repeat;
  animation: ${rightRotate} 25s linear infinite both;
`;

const BgDiv1 = styled.div`
  position: absolute;
  bottom: -300px;
  right: -300px;
  width: 600px;
  height: 600px;
  background-size: cover;
  background-image: url(${bgicon1});
  background-repeat: no-repeat;
  animation: ${rightRotate} 25s linear infinite both;
`;

const MainContainer = styled.div`
  width: 700px;
  margin: 200px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
`;

const Text = styled.div`
  font-size: 28px;
  color: #5a5b5e;

  & > span {
    display: block;
    text-align: center;
    font-weight: 600;
  }
`;

const P = styled.p`
  font-size: 100px;
  font-weight: 900;
  line-height: 1em;
  color: #cb2058;
`;

const Button = styled.div`
  width: 520px;
  height: 128px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background: #cb2058;
  border-radius: 10px;
  margin: 80px auto;
  margin-bottom: 110px;
  box-shadow: 8px 10px 20px rgba(90, 91, 94, 0.26);
  & > span {
    font-size: 30px;
    font-weight: 600;
    color: white;
  }
`;

const Icon = styled.img`
  margin: 0 86px 0 0;
  width: ${(props) => (props.width ? `${props.width}px` : "28px")};

  &:nth-of-type(5) {
    margin: 0;
  }
`;

export default Screen;

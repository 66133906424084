import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment-timezone";
import AddFilter from "./AddFilter";
import Input from "./Input";
import InputSelect from "./InputSelect";

function B2CModiModal({ selectItem, setSelectItem, startTimeList, timeList }) {
  const onChange = (e) => {
    const { value, name } = e.target;
    setSelectItem({ ...selectItem, [name]: value });
  };

  useEffect(() => {
    setSelectItem({
      ...selectItem,
      reserved_time: moment(selectItem.reserved_dt).utc().format("HH:mm"),
      start_time: moment(selectItem.start_dt).utc().format("HH:mm"),
      reserved_dt: moment(selectItem.reserved_dt).utc().format("YYYY-MM-DD"),
      start_dt: moment(selectItem.start_dt).utc().format("YYYY-MM-DD"),
    });
  }, []);

  return (
    <>
      <Label>수정하기</Label>
      <ModalLine>
        <ModalLabel>
          <InputLabel>예약번호</InputLabel>
          <Input
            value={selectItem.resv_num || ""}
            name="resv_num"
            onChange={onChange}
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>언어</InputLabel>
          <TextDiv readOnly name="lang" value={selectItem.lang || ""} />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>신청자명</InputLabel>
          <Input
            value={selectItem.name || ""}
            name="name"
            onChange={onChange}
          />
        </ModalLabel>
      </ModalLine>
      <ModalLine>
        <AddFilter
          label="관람일시"
          name="start_dt"
          item={selectItem}
          setItem={setSelectItem}
          onChange={onChange}
          value={selectItem.start_dt || ""}
        />
        <AddFilter
          name="start_time"
          selectList={startTimeList}
          item={selectItem}
          setItem={setSelectItem}
          onChange={onChange}
          value={selectItem.start_time || ""}
        />
        <span style={{ marginBottom: "7px" }}></span>
        <AddFilter
          label="접수일"
          name="reserved_dt"
          item={selectItem}
          setItem={setSelectItem}
          value={selectItem.reserved_dt || ""}
          onChange={onChange}
        ></AddFilter>
        <InputSelect
          value={
            String(selectItem.reserved_time)
              .replace(/\s/gi, "")
              .replace(/(\d{2})(\d{2})/, "$1:$2") || ""
          }
          name="reserved_time"
          selectList={timeList}
          selectData={selectItem}
          setSelectData={setSelectItem}
          onChange={onChange}
          style={{ maxWidth: "130px" }}
        />
      </ModalLine>
      <ModalLine>
        <ModalLabel>
          <InputLabel>총 관람인원</InputLabel>
          <TotalInput
            readOnly
            onChange={onChange}
            value={
              Number(selectItem.visitor_mount_1) +
              Number(selectItem.visitor_mount_2) +
              Number(selectItem.visitor_mount_3) +
              Number(selectItem.visitor_mount_4) +
              Number(selectItem.visitor_mount_5)
            }
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>임직원(LG계열사)</InputLabel>
          <Input
            value={selectItem.visitor_mount_1 || ""}
            name="visitor_mount_1"
            onChange={onChange}
            type="number"
            pattern="[0-9]*"
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>일반 성인</InputLabel>
          <Input
            value={selectItem.visitor_mount_2 || ""}
            name="visitor_mount_2"
            onChange={onChange}
            type="number"
            pattern="[0-9]*"
          />
        </ModalLabel>
      </ModalLine>
      <ModalLine>
        <ModalLabel>
          <InputLabel>대학생</InputLabel>
          <Input
            value={selectItem.visitor_mount_3 || ""}
            name="visitor_mount_3"
            onChange={onChange}
            type="number"
            pattern="[0-9]*"
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>중/고등학생</InputLabel>
          <Input
            value={selectItem.visitor_mount_4 || ""}
            name="visitor_mount_4"
            onChange={onChange}
            type="number"
            pattern="[0-9]*"
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>초등학생 이하</InputLabel>
          <Input
            value={selectItem.visitor_mount_5 || ""}
            name="visitor_mount_5"
            onChange={onChange}
            type="number"
            pattern="[0-9]*"
          />
        </ModalLabel>
      </ModalLine>
      <ModalLine>
        <ModalLabelBox>
          <InputLabel>이메일계정</InputLabel>
          <Input
            value={selectItem.email || ""}
            name="email"
            onChange={onChange}
          />
        </ModalLabelBox>
      </ModalLine>
    </>
  );
}

const Label = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #cb2058;
  margin-bottom: 20px;
`;

const InputLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #5a5b5e;
  margin-bottom: 4px;
  padding-left: 2px;
`;

const ModalLine = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 20px;
  justify-content: start;

  & > span {
    margin: 0 10px;
  }
`;

const ModalLabel = styled.div`
  flex: 1;
  margin-right: 10px;

  &:nth-of-type(3) {
    margin-right: 0;
  }
  & > p {
    color: #5a5b5e;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 4px;
  }
`;

const ModalLabelBox = styled(ModalLabel)`
  margin-right: 0;
`;

const TextDiv = styled.input`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: none;
  padding: 10px;
  font-size: 100%;
`;

const TotalInput = styled.input`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: none;
  padding: 10px;
  font-size: 100%;

  &:focus {
    border: unset;
  }
`;
export default B2CModiModal;

import React from "react";
import styled, { css } from "styled-components";
import axios from "axios";
import moment from "moment";
import icon from "../../assets/icon/person.svg";
import "moment/locale/ko";

function Visitor({ setStep, selectedDate, item, setItem, selectedData }) {
  const home = () => {
    setStep("date");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "total_mount") {
      setItem({
        ...item,
        visitor_mount_1: "0",
        visitor_mount_2: "0",
        visitor_mount_3: "0",
        visitor_mount_4: "0",
        visitor_mount_5: "0",
        total_mount: "0",
      });
    } else {
      setItem({
        ...item,
        [name]: value || "0",
      });
    }
  };
  const registration = async () => {
    const params = {
      visitor_mount_1: item.visitor_mount_1
        ? item.visitor_mount_1.toString()
        : selectedData.visitor_mount_1.toString(),
      visitor_mount_2: item.visitor_mount_2
        ? item.visitor_mount_2.toString()
        : selectedData.visitor_mount_2.toString(),
      visitor_mount_3: item.visitor_mount_3
        ? item.visitor_mount_3.toString()
        : selectedData.visitor_mount_3.toString(),
      visitor_mount_4: item.visitor_mount_4
        ? item.visitor_mount_4.toString()
        : selectedData.visitor_mount_4.toString(),
      visitor_mount_5: item.visitor_mount_5
        ? item.visitor_mount_5.toString()
        : selectedData.visitor_mount_5.toString(),
    };

    await axios
      .post(
        `https://lginnovationgallery.com/api/client/timetable/reservation/basic/${selectedData.id}`,
        params
      )
      .then((res) => {
        if (res.data.resCode === 200) {
          setStep("done");
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <Container>
      <Date onClick={home}>{moment(selectedDate).format("YY.MM.DD (dd)")}</Date>
      <Icon src={icon} />
      <Title>
        <span>방문 인원 수</span>를 입력해주세요
      </Title>
      <InputContainer>
        <InputInner>
          <InputBox top="60px">
            <Text>임직원(LG 계열사)</Text>
            <div>
              <Input
                type="number"
                pattern="[0-9]*"
                placeholder={
                  item.total_mount === "0"
                    ? 0
                    : item.visitor_mount_1 === "0"
                    ? "0"
                    : selectedData.visitor_mount_1
                }
                name="visitor_mount_1"
                value={
                  item.visitor_mount_1 && item.visitor_mount_1 !== "0"
                    ? item.visitor_mount_1
                    : ""
                }
                onChange={handleInput}
              />
              명
            </div>
          </InputBox>
          <InputBox>
            <Text>일반 성인</Text>
            <div>
              <Input
                type="number"
                pattern="[0-9]*"
                placeholder={
                  item.total_mount === "0"
                    ? 0
                    : item.visitor_mount_2 === "0"
                    ? "0"
                    : selectedData.visitor_mount_2
                }
                name="visitor_mount_2"
                value={
                  item.visitor_mount_2 && item.visitor_mount_2 !== "0"
                    ? item.visitor_mount_2
                    : ""
                }
                onChange={handleInput}
              />
              명
            </div>
          </InputBox>
          <InputBox>
            <Text>대학생</Text>
            <div>
              <Input
                type="number"
                pattern="[0-9]*"
                placeholder={
                  item.total_mount === "0"
                    ? 0
                    : item.visitor_mount_3 === "0"
                    ? "0"
                    : selectedData.visitor_mount_3
                }
                name="visitor_mount_3"
                value={
                  item.visitor_mount_3 && item.visitor_mount_3 !== "0"
                    ? item.visitor_mount_3
                    : ""
                }
                onChange={handleInput}
              />
              명
            </div>
          </InputBox>
          <InputBox>
            <Text>중/고등학생</Text>
            <div>
              <Input
                type="number"
                pattern="[0-9]*"
                placeholder={
                  item.total_mount === "0"
                    ? 0
                    : item.visitor_mount_4 === "0"
                    ? "0"
                    : selectedData.visitor_mount_4
                }
                name="visitor_mount_4"
                value={
                  item.visitor_mount_4 && item.visitor_mount_4 !== "0"
                    ? item.visitor_mount_4
                    : ""
                }
                onChange={handleInput}
              />
              명
            </div>
          </InputBox>
          <InputBox bottom="40px">
            <Text>초등학생 이하</Text>
            <div>
              <Input
                type="number"
                pattern="[0-9]*"
                placeholder={
                  item.total_mount === "0"
                    ? 0
                    : item.visitor_mount_5 === "0"
                    ? "0"
                    : selectedData.visitor_mount_5
                }
                name="visitor_mount_5"
                value={
                  item.visitor_mount_5 && item.visitor_mount_5 !== "0"
                    ? item.visitor_mount_5
                    : ""
                }
                onChange={handleInput}
              />
              명
            </div>
          </InputBox>
        </InputInner>
        <TotalBox>
          <TotalInput>
            <div>
              총
              <Input
                type="number"
                pattern="[0-9]*"
                placeholder={selectedData.total_mount}
                name="total_mount"
                onChange={handleInput}
                value={
                  !item.visitor_mount_1 &&
                  !item.visitor_mount_2 &&
                  !item.visitor_mount_3 &&
                  !item.visitor_mount_4 &&
                  !item.visitor_mount_5
                    ? item.total_mount || ""
                    : (
                        Number(
                          item.visitor_mount_1
                            ? item.visitor_mount_1
                            : selectedData.visitor_mount_1
                        ) +
                        Number(
                          item.visitor_mount_2
                            ? item.visitor_mount_2
                            : selectedData.visitor_mount_2
                        ) +
                        Number(
                          item.visitor_mount_3
                            ? item.visitor_mount_3
                            : selectedData.visitor_mount_3
                        ) +
                        Number(
                          item.visitor_mount_4
                            ? item.visitor_mount_4
                            : selectedData.visitor_mount_4
                        ) +
                        Number(
                          item.visitor_mount_5
                            ? item.visitor_mount_5
                            : selectedData.visitor_mount_5
                        )
                      ).toString()
                }
              />
              명
            </div>
          </TotalInput>
        </TotalBox>
      </InputContainer>
      <BtnBox>
        <Button
          onClick={() => {
            setStep("reservationList");
          }}
        >
          이전
        </Button>
        <NextButton
          onClick={() => {
            registration();
          }}
        >
          제출
        </NextButton>
      </BtnBox>
      <StepBox>
        <span />
        <span />
        <span />
      </StepBox>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 700px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Date = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 150px;
  height: 36px;
  background: #aeb0b3;
  box-shadow: 0px 3px 3px rgb(90 91 94 / 26%);
  border-radius: 16px;
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 16px;
  cursor: pointer;
`;

const Icon = styled.img`
  margin-top: 95px;
`;

const Title = styled.p`
  margin-top: 20px;
  font-size: 24px;
  font-weight: 500;
  color: #5a5b5e;

  & > span {
    font-weight: 700;
  }
`;

const InputContainer = styled.div`
  width: 700px;
  height: 660px;
  border: 1px solid rgba(174, 176, 179, 0.5);
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 40px;
  background: #fff;
`;

const InputBox = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}` : "30px")};
  margin-top: ${(props) => props.top && props.top};

  & > div {
    color: #5a5b5e;
    font-size: 22px;
    font-weight: 700;
  }
`;

const Text = styled.p`
  width: 230px;
  color: #5a5b5e;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const InputInner = styled.div`
  overflow: hidden;
`;

const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border-radius: 10px;
  width: 200px;
  height: 60px;
  padding: 0 10px;
  margin-right: 10px;
  text-align: end;
  font-size: 22px;
  font-weight: 700;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
    border: 1px solid #cb2058;
    box-shadow: 2px 4px 10px inset rgb(203 32 88 / 12%);
    color: #cb2058;
  }

  ${(props) =>
    props.value <= 0
      ? css`
          color: #5a5b5e;
          border: 1px solid rgb(255 255 255 / 70%);
          box-shadow: 2px 4px 10px inset rgb(90 91 94 / 10%);
        `
      : css`
          color: #cb2058;
          border: 1px solid #cb2058;
          box-shadow: 2px 4px 10px inset rgb(203 32 88 / 12%);
        `};
`;

const TotalBox = styled.div`
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    width: 590px;
    height: 1px;
    background: #5a5b5e;
    position: absolute;
    left: 70px;
  }
`;

const TotalInput = styled.div`
  float: right;
  margin: 40px 60px 40px;
  font-size: 22px;
  font-weight: 700;
  color: #5a5b5e;
`;

const BtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

const Button = styled.button`
  width: 156px;
  height: 54px;
  border: 1px solid rgb(245 245 245 / 40%);
  box-shadow: 2px 10px 20px rgb(90 91 94 / 12%);
  border-radius: 10px;
  box-sizing: border-box;
  color: #aeb0b3;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  background: #fff;

  &:hover {
    color: #fff;
    background: #cb2058;
    border: 0px solid rgb(255 255 255 /10%);
    box-shadow: 8px 10px 20px rgb(90 91 94 /26%);
    border: 0;
    font-weight: 700;
  }
`;

const NextButton = styled(Button)`
  background: ${(props) => (props.disabled ? "white" : "#cb2058;")};
  color: ${(props) => (props.disabled ? "#aeb0b3" : "white")};
  box-shadow: ${(props) =>
    props.disabled
      ? "8px 10px 20px rgb(90 91 94 /26%)"
      : "2px 10px 20px rgb(90 91 94 / 12%)"};
  border: ${(props) =>
    props.disabled ? "1px solid rgb(245 245 245 / 40%)" : "0"};
  font-weight: ${(props) => (props.disabled ? "400" : "700")};
`;

const StepBox = styled.div`
  width: 74px;
  height: 22px;
  margin: 30px 0 90px;

  & > span {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    display: block;
    background: #fff;
    float: left;
    margin-right: 10px;
    box-shadow: 0px 2px 4px inset rgb(90 91 94 / 12%);

    &:nth-of-type(3) {
      background: #cb2058;
      border: 1px solid rgb(255 255 255 /10%);
      box-shadow: -3px 4px 7px 2px rgb(203 32 88 / 26%);
      box-sizing: border-box;
      margin-right: 0;
    }
  }
`;

export default Visitor;

import React, { useEffect, useState } from "react";
import { withRouter, Route } from "react-router-dom";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import B2B from "../components/Admin/B2B";
import B2C from "../components/Admin/B2C";
import Navigation from "../components/Admin/Navigation";
import Login from "../components/Admin/Login";
import bg from "../assets/icon/loginbg.jpg";

function Admin({ history, location }) {
  const [path, setPath] = useState(window.location.pathname.split("/"));
  const [cookies, setCookie, removeCookie] = useCookies(["isLogin"]);

  useEffect(() => {
    const initPath = window.location.pathname.split("/");
    setPath(initPath);
    if (cookies.isLogin === undefined) {
      history.push("/admin/login");
    } else if (!initPath[2]) {
      if (!cookies.isLogin) {
        history.push("/admin/login");
      } else {
        history.push("/admin/b2b");
      }
    }
  }, [location.pathname]);

  return (
    <>
      {path[2] !== "login" && <Navigation />}
      <AdminContainer>
        <Route path="/admin/login" component={Login} />
        <Route path="/admin/b2b" component={B2B} />
        <Route path="/admin/b2c" component={B2C} />
      </AdminContainer>
    </>
  );
}

const AdminContainer = styled.div`
  padding: 70px 40px 50px 40px;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #f8f8f8;
`;

const Header = styled.div`
  color: #cb2058;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 24px;
`;

export default withRouter(Admin);

import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import moment from "moment-timezone";
import Input from "./Input";
import AddFilter from "./AddFilter";
import Checkbox from "./CheckBox";
import InputSelect from "./InputSelect";

function ModiModal({
  selectItem,
  setSelectItem,
  filterList,
  programList,
  langFilterList,
  interestingList,
  timeList,
  companyList,
}) {
  const onChange = (e) => {
    const { value, name } = e.target;
    setSelectItem({ ...selectItem, [name]: value });
  };

  const handleAllCheck = (checked) => {
    if (checked) {
      setSelectItem({ ...selectItem, interesting: interestingList });
    } else {
      setSelectItem({ ...selectItem, interesting: [] });
    }
  };

  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setSelectItem({
        ...selectItem,
        interesting: [...selectItem.interesting, id],
      });
    } else {
      setSelectItem({
        ...selectItem,
        interesting: selectItem.interesting.filter((el) => el !== id),
      });
    }
  };

  useEffect(() => {
    setSelectItem({
      ...selectItem,
      interesting: selectItem ? selectItem.interesting.split(",") : [],
      reserved_time: moment(selectItem.reserved_dt).utc().format("HH:mm"),
      start_time: moment(selectItem.start_dt).utc().format("HH:mm"),
      end_time: moment(selectItem.end_dt).utc().format("HH:mm"),
    });
  }, []);

  return (
    <>
      <Label>수정하기</Label>
      <ModalLine>
        <ModalLabel>
          <InputLabel>제목</InputLabel>
          <Input
            value={selectItem.title || ""}
            name="title"
            onChange={onChange}
          />
        </ModalLabel>
      </ModalLine>

      <ModalLineDiv>
        <AddFilter
          label="언어"
          selectList={langFilterList}
          name="lang"
          item={selectItem}
          setItem={setSelectItem}
          value={selectItem.lang || ""}
        />
        <ModalLineDiv1>
          <span style={{ marginBottom: "7px" }}>&nbsp;</span>
          <AddFilter
            label="요청일"
            name="reserved_dt"
            item={selectItem}
            setItem={setSelectItem}
            value={selectItem.reserved_dt || ""}
            onChange={onChange}
          ></AddFilter>
          <InputSelect
            name="reserved_time"
            selectList={timeList}
            selectData={selectItem}
            setSelectData={setSelectItem}
            onChange={onChange}
            value={
              String(selectItem.reserved_time)
                .replace(/\s/gi, "")
                .replace(/(\d{2})(\d{2})/, "$1:$2") || ""
            }
            style={{ maxWidth: "130px" }}
          />
        </ModalLineDiv1>
      </ModalLineDiv>

      <ModalLineDiv>
        <AddFilter
          label="예약일자"
          name="start_dt"
          item={selectItem}
          setItem={setSelectItem}
          onChange={onChange}
          value={selectItem.start_dt || ""}
        />
        <InputSelect
          name="start_time"
          selectList={timeList}
          selectData={selectItem}
          setSelectData={setSelectItem}
          onChange={onChange}
          value={
            String(selectItem.start_time)
              .replace(/\s/gi, "")
              .replace(/(\d{2})(\d{2})/, "$1:$2") || ""
          }
          placeholder="00:00"
        />
        <span style={{ marginBottom: "7px" }}>~</span>
        <AddFilter
          name="end_dt"
          item={selectItem}
          setItem={setSelectItem}
          onChange={onChange}
          value={selectItem.end_dt || ""}
        />
        <InputSelect
          name="end_time"
          selectList={timeList}
          selectData={selectItem}
          setSelectData={setSelectItem}
          onChange={onChange}
          value={
            String(selectItem.end_time)
              .replace(/\s/gi, "")
              .replace(/(\d{2})(\d{2})/, "$1:$2") || ""
          }
          placeholder="00:00"
        />
      </ModalLineDiv>
      <ModalLine>
        <ModalLabel>
          <InputLabel>고객사 방문인원(명)</InputLabel>
          <Input
            type="number"
            pattern="[0-9]*"
            value={selectItem.visitor_mount_2 || ""}
            name="visitor_mount_2"
            onChange={onChange}
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>LG 방문인원(명)</InputLabel>
          <Input
            type="number"
            pattern="[0-9]*"
            value={selectItem.visitor_mount_1 || ""}
            name="visitor_mount_1"
            onChange={onChange}
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>방문인원(총인원)</InputLabel>
          <TotalInput
            readOnly
            value={
              Number(selectItem.visitor_mount_1) +
              Number(selectItem.visitor_mount_2)
            }
          />
        </ModalLabel>
      </ModalLine>
      <ModalLine>
        <ModalLabel>
          <InputLabel>요청자</InputLabel>
          <Input
            value={selectItem.name || ""}
            name="name"
            onChange={onChange}
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>직책</InputLabel>
          <Input
            value={selectItem.position || ""}
            name="position"
            onChange={onChange}
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>팀명</InputLabel>
          <Input
              value={selectItem.team || ""}
              name="team"
              onChange={onChange}
          />
        </ModalLabel>
      </ModalLine>
      <ModalLine>
        <ModalLabel>
          <AddFilter
            label="회사"
            name="company"
            item={selectItem}
            setItem={setSelectItem}
            selectList={companyList}
            value={selectItem.company || ""}
            onChange={onChange}
          />
        </ModalLabel>
        <ModalLabel>
          <InputLabel>비고</InputLabel>
          <Input
            value={selectItem.company_note || ""}
            name="company_note"
            onChange={onChange}
          />
        </ModalLabel>
      </ModalLine>
      <ModalLine>
        {filterList?.map(
          (fliter, index) =>
            index !== 0 && (
              <AddFilter
                key={`modifilter${index}`}
                label={fliter.label}
                selectList={fliter.list}
                setItem={setSelectItem}
                item={selectItem}
                name={fliter.serverName}
                value={selectItem[fliter.serverName] || ""}
                onChange={onChange}
              />
            )
        )}
      </ModalLine>
      <ModalLine>
        <ModalLabel>
          <InputLabel>고객사정보</InputLabel>
          <Input
            value={selectItem.info || ""}
            name="info"
            onChange={onChange}
          />
        </ModalLabel>
        <ModalLabel>
          <AddFilter
            label="투어프로그램"
            name="program"
            selectList={programList}
            item={selectItem}
            setItem={setSelectItem}
            value={selectItem.program || ""}
            onChange={onChange}
          />
        </ModalLabel>
      </ModalLine>
      <ModalLine>
        <ModalLabel>
          <InputLabel>주요 관심분야</InputLabel>
          <InterestingTxt>
            <CheckBoxWrapper key="Allcheck">
              <Checkbox
                onChange={(e) => handleAllCheck(e.target.checked)}
                checked={
                  interestingList.length === selectItem.interesting?.length
                }
              />
              <CheckText>전체</CheckText>
            </CheckBoxWrapper>
          </InterestingTxt>
          <CheckWrap>
            {interestingList.map((interesting, idx) => (
              <CheckBoxWrapper key={`check${idx}`}>
                <Checkbox
                  name="interesting"
                  value={interesting || ""}
                  onChange={(e) =>
                    handleSingleCheck(e.target.checked, interesting)
                  }
                  checked={selectItem.interesting?.includes(interesting)}
                />
                <CheckText>{interesting}</CheckText>
              </CheckBoxWrapper>
            ))}
          </CheckWrap>
        </ModalLabel>
      </ModalLine>
    </>
  );
}

const Label = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #cb2058;
  margin-bottom: 20px;
`;

const InputLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #5a5b5e;
  margin-bottom: 4px;
  padding-left: 2px;
`;

const ModalLine = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
`;

const ModalLineDiv = styled(ModalLine)`
  gap: 0;

  & > span {
    margin: 0 10px;
  }
`;
const ModalLineDiv1 = styled(ModalLine)`
  gap: 0;
  max-width: 290px;
  margin-bottom: 0;

  & > span {
    margin: 0 10px;
  }
`;

const ModalLabel = styled.div`
  flex: 1;
`;

const CheckWrap = styled.div`
  background: #fff;
  display: grid;
  grid-template-columns: repeat(5, 3fr);
  row-gap: 15px;
  margin-top: 15px;
`;
const CheckBoxWrapper = styled.label`
  display: flex;
  font-weight: 700;
  color: #5a5b5e;
`;

const CheckText = styled.p`
  margin-left: 10px;
  font-size: 12px;
`;

const InterestingTxt = styled.div`
  margin-top: 15px;
`;

const TotalInput = styled.input`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: none;
  padding: 10px;
  font-size: 100%;

  &:focus {
    border: unset;
  }
`;

export default ModiModal;

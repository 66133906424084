import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import moment from "moment";
import icon from "../../assets/icon/basicstep4.svg";
import "moment/locale/ko";

function BasicList({
  setStep,
  selectedDate,

  item,
  setItem,
  setSelectedData,
  selectedData,
}) {
  const home = () => {
    setStep("date");
  };
  const [list, setList] = useState([]);
  const getList = async () => {
    const date = moment(selectedDate).format("YYYY-MM-DD");
    const time = selectedData.start_time;
    await axios
      .get(
        `https://lginnovationgallery.com/api/client/timetable/reservation/basic?date=${date}&time=${time}`
      )
      .then((res) => {
        setList(res.data.result);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    if (item) {
      getList();
    }
  }, [item, selectedDate]);

  const clickItem = (i) => {
    setSelectedData({ ...selectedData, ...i });
    getList(selectedDate);
    setActive(i.id);
    setItem({});
  };

  const [active, setActive] = useState();

  return (
    <Container>
      <Date onClick={home}>{moment(selectedDate).format("YY.MM.DD (dd)")}</Date>
      <Icon src={icon} />
      <Title>
        <span>예약자 명 / 예약 번호</span>를 선택해주세요
      </Title>
      <ListBox>
        {list.map((i, index) => (
          <List
            active={active === i.id}
            isRegistered={i.is_registered}
            onClick={() => clickItem(i)}
            key={`list${index}`}
          >
            <ListText> {i.title}</ListText>
          </List>
        ))}
      </ListBox>
      <BtnBox>
        <PrevButton
          onClick={() => {
            setStep("list");
          }}
        >
          이전
        </PrevButton>
        <Button
          disabled={!active}
          onClick={() => {
            setStep("visitor");
          }}
        >
          다음
        </Button>
      </BtnBox>
      <StepBox>
        <span />
        <span />
        <span />
      </StepBox>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 700px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Date = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 150px;
  height: 36px;
  background: #aeb0b3;
  box-shadow: 0px 3px 3px rgb(90 91 94 / 26%);
  border-radius: 16px;
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 16px;
  cursor: pointer;
`;

const Icon = styled.img`
  margin-top: 95px;
`;

const Title = styled.p`
  margin-top: 20px;
  font-size: 24px;
  font-weight: 500;
  color: #5a5b5e;

  & > span {
    font-weight: 700;
  }
`;

const ListBox = styled.div`
  width: 710px;
  height: 334px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  padding: 40px 10px 74px;
`;

const List = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 140px;
  border: 1px solid #fcfcfc;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 20px;
  text-align: center;
  color: ${(props) => (props.isRegistered === 1 ? "#cb2058" : "#77797c")};
  box-shadow: ${(props) =>
    props.isRegistered === 1
      ? "2px 10px 20px inset rgb(90 91 94 / 12%)"
      : "2px 10px 20px rgb(90 91 94 / 10%)"};
  cursor: pointer;
  background: #fff;
  ${({ active }) =>
    active &&
    `
    background: #cb2058;
    color: #fff;
    font-weight: 700;
    border:0px solid rgb( 255 255 255 /10%);
    box-shadow: 8px 10px 20px rgb(90 91 94 / 26%)
  `}
`;

const ListText = styled.div`
  white-space: pre-wrap;
`;

const BtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

const Button = styled.button`
  width: 156px;
  height: 54px;
  border: ${(props) => (props.disabled ? "1px solid" : null)};
  border-color: ${(props) =>
    props.disabled ? "rgb(245 245 245 / 40%)" : null};

  box-shadow: 2px 10px 20px rgb(90 91 94 / 12%);
  border-radius: 10px;
  box-sizing: border-box;
  color: ${(props) => (props.disabled ? "#aeb0b3" : "#fff")};
  background: ${(props) => (props.disabled ? "#fff" : "#cb2058;")};
  text-align: center;
  font-size: 20px;
  font-weight: ${(props) => (props.disabled ? "400" : "700")};

  &:hover {
    color: #fff;
    background: #cb2058;
    box-shadow: 8px 10px 20px rgb(90 91 94 /26%);
    border: 0px solid rgb(255 255 255 /10%);
    font-weight: 700;
  }
`;

const PrevButton = styled(Button)`
  background: #fff;
  color: #aeb0b3;
  font-weight: unset;
`;

const StepBox = styled.div`
  width: 74px;
  height: 22px;
  margin: 30px auto;

  & > span {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    display: block;
    background: #fff;
    float: left;
    margin-right: 10px;
    box-shadow: 0px 2px 4px inset rgb(90 91 94 / 12%);

    &:nth-of-type(2) {
      background: #cb2058;
      border: 1px solid rgb(255 255 255 /10%);
      box-shadow: -3px 4px 7px 2px rgb(203 32 88 / 26%);
      box-sizing: border-box;
    }

    &:nth-of-type(3) {
      margin-right: 0;
    }
  }
`;

export default BasicList;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import styled, { css } from "styled-components";
import moment from "moment-timezone";
import axios from "axios";
import Checkbox from "./CheckBox";
import Modal from "./Modal";
import B2CModiModal from "./B2CModiModal";

function B2CTable({
  list,
  checkItems,
  setCheckItems,
  langFilterList,
  timeList,
  startTimeList,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectItem, setSelectItem] = useState({});

  const editItem = (item) => {
    setModalOpen(true);
    setSelectItem(item);
  };

  const modalClose = () => {
    setModalOpen(false);
  };

  // 수정하기

  const [flag, setFlag] = useState(false);
  const saveItem = async () => {
    const params = {
      name: selectItem.name,
      startDt: `${moment(selectItem.start_dt).format("YYYY-MM-DD")} ${String(
        selectItem.start_time
      ).split(" ", 1)}`,
      endDt: `${moment(selectItem.end_dt).format("YYYY-MM-DD")} ${String(
        selectItem.end_time
      )
        .replace(/\s/gi, "")
        .replace(/(\d{2})(\d{2})/, "$1:$2")}`,
      reservedDt: `${moment(selectItem.reserved_dt).format(
        "YYYY-MM-DD"
      )} ${String(selectItem.reserved_time)
        .replace(/\s/gi, "")
        .replace(/(\d{2})(\d{2})/, "$1:$2")}`,
      lang: selectItem.lang,
      visitorMount1: String(selectItem.visitor_mount_1),
      visitorMount2: String(selectItem.visitor_mount_2),
      visitorMount3: String(selectItem.visitor_mount_3),
      visitorMount4: String(selectItem.visitor_mount_4),
      visitorMount5: String(selectItem.visitor_mount_5),
      email: selectItem.email,
    };
    if (!flag) {
      setFlag(true);
      await axios
        .put(
          `https://lginnovationgallery.com/api/admin/basic/edit/${selectItem.id}`,
          params
        )
        .then((res) => {
          console.log(res.data);
          setTimeout(() => {
            setFlag(false);
            modalClose();
            window.location.reload();
          }, []);
        })
        .catch((error) => {
          console.log(error.response);
          setTimeout(() => {
            setFlag(false);
          }, []);
        });
    }
  };

  const handleAllCheck = (checked) => {
    if (checked) {
      let temp = [];
      list.map((item) => (temp = [...temp, item.id]));
      setCheckItems(temp);
    } else {
      setCheckItems([]);
    }
  };

  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheckItems([...checkItems, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  return (
    <>
      {modalOpen && (
        <Modal open={modalOpen} close={modalClose} onClick={saveItem}>
          <B2CModiModal
            langFilterList={langFilterList}
            selectItem={selectItem}
            setSelectItem={setSelectItem}
            timeList={timeList}
            startTimeList={startTimeList}
          />
        </Modal>
      )}
      <TableContainer>
        <TableInner>
          <TableRow header>
            <Col width={30}>
              <label>
                <Checkbox
                  onChange={(e) => handleAllCheck(e.target.checked)}
                  checked={
                    list.length > 0 ? checkItems.length === list.length : false
                  }
                />
              </label>
            </Col>
            <Col>예약번호</Col>
            <Col width={70}>언어</Col>
            <Col>신청자명</Col>
            <Col width={183}>관람일시</Col>
            <Col>접수일</Col>
            <Col width={60}>상태</Col>
            <Col>총 관람인원</Col>
            <Col>임직원(LG계열사)</Col>
            <Col>일반 성인</Col>
            <Col>대학생</Col>
            <Col>중/고등학생</Col>
            <Col>초등학생 이하</Col>
            <Col>이메일계정</Col>
            <Col>회사</Col>
            <Col width={60}>관리</Col>
          </TableRow>
          {list?.map((item, index) => (
            <TableRow
              key={`b2cTable${index}`}
              checked={!!checkItems.includes(item.id)}
            >
              <Col width={30}>
                <label>
                  <Checkbox
                    onChange={(e) =>
                      handleSingleCheck(e.target.checked, item.id)
                    }
                    checked={!!checkItems.includes(item.id)}
                  />
                </label>
              </Col>
              <Col>{item.resv_num}</Col>
              <Col width={70}>{item.lang}</Col>
              <Col>{item.name}</Col>
              <Col width={183}>
                {item.start_time === Number(1000)
                  ? "10:00 1회차"
                  : item.start_time === Number(1100)
                  ? "11:00 2회차"
                  : item.start_time === Number(1900)
                  ? "19:00 3회차"
                  : "0회차"}
              </Col>
              <Col>
                {item.reserved_dt &&
                  moment(item.reserved_dt).utc().format("YYYY-MM-DD HH:mm")}
              </Col>
              <Col width={60}>{item.status}</Col>
              <Col>
                {item.total_mount}
                <>
                  {item.is_registered === 1 && (
                    <span>&nbsp;{`| ${item.registered_total_mount}`}</span>
                  )}
                </>
              </Col>
              <Col>
                {item.visitor_mount_1}
                <>
                  {item.is_registered === 1 && (
                    <span>&nbsp;{`| ${item.registered_visitor_mount_1}`}</span>
                  )}
                </>
              </Col>
              <Col>
                {item.visitor_mount_2}
                <>
                  {item.is_registered === 1 && (
                    <span>&nbsp;{`| ${item.registered_visitor_mount_2}`}</span>
                  )}
                </>
              </Col>
              <Col>
                {item.visitor_mount_3}
                <>
                  {item.is_registered === 1 && (
                    <span>&nbsp;{`| ${item.registered_visitor_mount_3}`}</span>
                  )}
                </>
              </Col>
              <Col>
                {item.visitor_mount_4}
                <>
                  {item.is_registered === 1 && (
                    <span>&nbsp;{`| ${item.registered_visitor_mount_4}`}</span>
                  )}
                </>
              </Col>
              <Col>
                {item.visitor_mount_5}
                <>
                  {item.is_registered === 1 && (
                    <span>&nbsp;{`| ${item.registered_visitor_mount_5}`}</span>
                  )}
                </>
              </Col>
              <Col>{item.email}</Col>
              <Col>{item.company}</Col>
              <Col width={60} onClick={() => editItem(item)}>
                <EditBtn>수정</EditBtn>
              </Col>
            </TableRow>
          ))}
        </TableInner>
      </TableContainer>
    </>
  );
}

const TableContainer = styled.div`
  width: auto;
  overflow-x: auto;
  margin-top: 6px;
  color: #5a5b5e;
  font-size: 10px;
`;

const TableInner = styled.div`
  min-width: fit-content;
  width: 100%;
`;

const Col = styled.div`
  padding: 0 4px;
  width: ${(props) => (props.width ? `${props.width}px` : "150px")};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  & > span {
    color: #cb2058;
  }
`;

const TableRow = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  background: ${(props) => (props.checked ? "#F7DCE5" : "unset")};

  ${(props) =>
    props.header
      ? `
      & > ${Col}{
        background-color: rgba(174, 179, 178, 0.26);
        border-top: 1px solid rgba(174, 179, 178, 0.35);
        border-bottom: 1px solid rgba(174, 179, 178, 0.35);
        height: 24px;
        font-weight: 700;
       }
        `
      : css`
          height: 44px;
        `}
`;

const EditBtn = styled.button`
  background-color: #cb2058;
  color: white;
  width: 48px;
  height: 26px;
  text-align: center;
  border-radius: 2px;
  font-size: 10px;
`;

export default B2CTable;

import React, { useState, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import Logo from "../components/common/Logotest";
import bgicon from "../assets/icon/bgicon.svg";
import Step1 from "../components/common/Step1";
import Step2 from "../components/common/Step2";
import QR from "../components/common/QR";
import VipVisitor from "../components/vip/Visitor";
import VipPurpose from "../components/vip/Purpose";
import VipType from "../components/vip/Type";
import BasicList from "../components/basic/BasicList";
import BasicVisitor from "../components/basic/Visitor";
import Done from "../components/common/Done";
import Screen from "../components/common/Screen";

function Main(props) {
  const SCREENSAVER_DELAY_MS = 30000;

  const [screensaverActive, setScreensaverActive] = useState(false);
  const [screensaverVisible, setScreensaverVisible] = useState(false);
  const screensaverTimeout = useRef();
  const secondTimer = useRef();

  const activeScreensaver = useCallback(() => {
    setScreensaverActive(true);
    setScreensaverVisible(true);
    setStep("start");
  }, []);

  useEffect(() => {
    screensaverClicked();
  }, []);

  const screensaverClicked = useCallback(() => {
    setScreensaverActive(false);
    startTimeout();
  }, []);

  const startTimeout = useCallback(() => {
    clearTimeout(screensaverTimeout.current);
    clearTimeout(secondTimer.current);
    const timeout = setTimeout(() => activeScreensaver(), SCREENSAVER_DELAY_MS);
    screensaverTimeout.current = timeout;
  }, []);

  const [step, setStep] = useState("start");
  const [selectedDate, setSelectedDate] = useState("");
  const [item, setItem] = useState({});
  const [selectedData, setSelectedData] = useState({});

  return (
    <div
      onClick={screensaverClicked}
      onKeyDown={screensaverClicked}
      role="button"
      tabIndex={0}
    >
      {step === "start" && (
        <Screen
          id="screensaver"
          onClick={screensaverClicked}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
          setStep={setStep}
          onKeyDown={screensaverClicked}
          role="button"
          tabIndex={0}
        />
      )}
      <Wrap>
        <MainContainer>
          <Logo setStep={setStep} step={step} />
          {step === "date" && (
            <Step1
              setStep={setStep}
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
            />
          )}
          {step === "list" && (
            <Step2
              setStep={setStep}
              selectedDate={selectedDate}
              setItem={setItem}
              setSelectedData={setSelectedData}
            />
          )}
          {step === "qr" && (
            <QR setStep={setStep} selectedDate={selectedDate} />
          )}
          {selectedData?.type === "vip" ? (
            <>
              {step === "visitor" && (
                <VipVisitor
                  setStep={setStep}
                  selectedDate={selectedDate}
                  item={item}
                  setItem={setItem}
                  selectedData={selectedData}
                />
              )}
              {step === "purpose" && (
                <VipPurpose
                  setStep={setStep}
                  selectedDate={selectedDate}
                  item={item}
                  setItem={setItem}
                  selectedData={selectedData}
                />
              )}
              {step === "type" && (
                <VipType
                  setStep={setStep}
                  selectedDate={selectedDate}
                  item={item}
                  setItem={setItem}
                  selectedData={selectedData}
                />
              )}
            </>
          ) : (
            <>
              {step === "reservationList" && (
                <BasicList
                  setStep={setStep}
                  selectedDate={selectedDate}
                  item={item}
                  setItem={setItem}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                />
              )}
              {step === "visitor" && (
                <BasicVisitor
                  setStep={setStep}
                  selectedDate={selectedDate}
                  item={item}
                  setItem={setItem}
                  selectedData={selectedData}
                />
              )}
            </>
          )}
          {step === "done" && (
            <Done
              setStep={setStep}
              selectedDate={selectedDate}
              item={item}
              selectedData={selectedData}
            />
          )}
        </MainContainer>
      </Wrap>
    </div>
  );
}

const Wrap = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url(${bgicon});
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: right;
`;

const MainContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default Main;
